import Header from "../components/Haeder";
import FooterLandingPage from "../components/FooterLandingPage";

const TermsOfUse=()=>{
    return(
        <>
        <Header></Header>
        <div className="row" style={{ backgroundColor: "white" }}>
            <div className="col">
            <p style={{ color: "#172026", font: "normal normal 600 30px/60px Source Serif Pro" ,marginTop:"5%"}}>Terms of Use</p>
            <div style={{textAlign:"left",padding:"5%"}}>
                <p>
<p style={{fontWeight:"500"}}>1. Company and its Role: </p><br></br>
Indotechs Software Pvt. Ltd. is a company that is engaged in the following roles:  <br></br>
(a)To carry on the business of providing, importing, exporting, selling, purchasing, trading,
production, distribution, development, customisation or otherwise deal in mobile application,
programs, software packages, internet packages, web applications, products, portals, services,
applications, web designs & other related product/services  <br></br>
(b) To carry on in India and/or elsewhere the business of manufacturing, designing, marketing,
servicing, consulting, repairs, alterations, assembly, purchase, sale, export, import, transfer,
exchange, or otherwise dealing in all types of electrical and electronic devices, computers, parts,
accessories, or other related products.  <br></br>
(c) To carry on the business of establishing and/or promoting support and consulting services,
whether IT-enabled or otherwise, including but not limited to the maintenance of support centres,
solutions, R&D, IT consulting, placement services, web services, technology process outsourcing,
and acting as a consultant for the above-mentioned services, and to carry on the business of data
digitalisation.  <br></br>
(d) To operate technology data processing centre or providing management information analysis,
development accounting and business information, analysis, and providing data to corporate,
institutions, individuals in India and abroad to carry on the business of gathering, compiling,
distributing, selling, and providing access to information regarding financial operations, financial
status, creditworthiness, and rating consumer responses and management of businesses of all kinds
and descriptions and to provided other services through either computer-aided or telephone or
through any other mode in India or anywhere in the world ant to carry on the business of providing
Infrastructure management services and Application service provider (ASP) services or commercial
usage to corporate, institutions, individuals or legal entity whether in India or abroad. <br></br><br></br>
<p style={{fontWeight:"500"}}>2. Types of users and roles defined: </p> <br></br>
These Terms constitute a binding and enforceable legal contract between the Company and a user
(as defined below) or any end-user of the services (collectively, "you"). You represent and warrant
that you  <br></br>(a) have full legal capacity and authority to agree and bind yourself to these Terms, <br></br> (b) are
18 (eighteen) years of age or older, and <br></br> (c) are an Indian resident. If you represent an entity,
organization, or any other legal person, you confirm and represent that you have the necessary
power and authority to bind such entity, organization, or legal person to these Terms. 
These Terms also include our privacy policy, available at ("Privacy Policy"), and any internal
guidelines, supplementary terms, policies, or disclaimers made available or issued by us from time
to time. By continuing to access or use the platform, or any service on the platform, you signify
your acceptance of the terms. Further, you understand that the platform is intended for the use of
Indian residents only, and your continued access and/or use of the platform shall be construed as a
deemed declaration that you are an Indian resident. 
The Company reserves the right to make changes to these Terms by posting the new or updated
version, and your continued use and/or non-deletion of the Platform shall indicate your agreement
to such changes. As a result, we encourage you to continue to review the Terms whenever you
access or use the platform in order to stay up to date on any changes to these Terms that we may
make.
By using the Services, you agree that you have read, understood, and are bound by these Terms and
that you comply with the requirements listed herein. If you do not agree to all of these Terms or
comply with the requirements herein, please do not access the platform or use the services. <br></br><br></br>
<p style={{fontWeight:"500"}}>3. You become legally bound by accepting the terms and conditions.</p>  <br></br>
(a) This Terms of Use are an electronic record in terms of the Information Technology Act, 2000,
and rules thereunder as applicable, as well as the amended provisions pertaining to electronic
records in various statutes as amended by the Information Technology Act, 2000. This electronic
record is generated by a computer system and does not require any physical or digital signatures.  <br></br>
(b) This Terms of Use is published in accordance with the provisions of Rule 3 of the Information
Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the rules and
regulations, privacy policy, and Terms of Use for access or usage of the website and/or app.<br></br><br></br>
 <p style={{fontWeight:"500"}}>4. Usage definitions of free and paid models:</p>  <br></br>
(a) "Customer" means any person who contracts with you for the purchase of goods and/or services
and makes a payment or remittance thereunder.  <br></br>
(b) "Customer Payment Amount" means the amount paid by the customer to you, using the service,
for the purchase of any goods and/or services.  <br></br>
(c) "Merchant Services," the services or products provided by you to your customer(s), the
remittance or payment for which is to be made through the customer's valid accounts, card(s), or
bank account, using Indotechs Mobile Application Services.  <br></br>
(d) "Processing Mechanism" means the payment mechanism through the internet or such other
mode of payment and delivery as may be notified by Indotechs from time to time, including but not
limited to Immediate Payment Service (IMPS), National Electronic Funds Transfer (NEFT), Real
Time Gross Settlement (RTGS), and/or UPI (defined hereinafter).  <br></br>
(e) "Transaction" shall mean an electronic financial transaction undertaken using the Indotechs
Mobile Application Services.  <br></br>
(f) "UPI" means the Unified Payment Interface, which is a payment system that powers multiple
bank accounts into a single payment network of participating banks, permitting the merging of
several banking features, seamless fund routing, and merchant payments into one hood. <br></br><br></br>
<p style={{fontWeight:"500"}}>5. Payment Terms and Refund Policy:  </p><br></br>
(a) By accepting these Terms and Conditions, you expressly authorise Indotechs to hold, receive,
disburse, and settle funds on your behalf. Such authorisation shall permit Indotech's mobile
application to receive customer payment amounts pursuant to a transaction into Indotech’s account
(Delivery Versus Payment) to further process each transaction. Indotechs shall process and transfer
such payments received by it to the bank account designated by you for this purpose at the time of
registration and/or any such account as instructed by you ("Merchant Account"). The authorisation
provided hereunder shall remain in full force and effect until specifically terminated.  <br></br>
(b) By accepting these Terms and Conditions, you also expressly authorise Indotechs to hold,
receive, and settle funds on your behalf for such other value-added services as may be opted for and
instructed by you on the permitted use of the application.  <br></br>
(c) Indotechs shall initiate a transfer of the relevant customer payment amount received by it to the
respective merchant account within T (such) banking days as per annexe A (‘T’ being the date of a
successful transaction and banking days being days on which private banks are open for business in
New Delhi) from the date of receipt of the relevant customer payment amount, after deduction of
Indotechs' fees and charges as agreed under these terms and conditions. For clarity, you
acknowledge that Indotech shall be liable to settle customer payment amounts only upon actual
receipt thereof. <br></br><br></br>
<p style={{fontWeight:"500"}}>6. Right to Amendment:  </p><br></br>
(a) The company reserves the right to modify the platform and/or alter these terms and/or policies at
any time and retains the right to deny access at any time, including the termination of membership
and deletion of the account, to anyone whom the company believes has violated the provisions of
the User Agreement.  <br></br>
(b) You are advised that any amendment to the User Agreement incorporated herein by reference
will only be notified on the Platform on publicly accessible links and You agree by accessing,
browsing or using the Platform that such publication shall immediately constitute sufficient notice
for all purposes against You.  The revised version and terms will become effective when the
company publishes them on the Platform.  <br></br>
(c) The Company shall not be liable to give any prior notice to the Users for any amendments to the
User Agreement that, in the opinion of the Company, have the effect of diminishing the User’s
existing rights or imposing additional obligations. You understand and hereby accept that the
decision as to whether any proposed amendments to the User Agreement have the effect of
diminishing the User’s existing rights or imposing additional obligations on the User shall be
decided by the Company in its sole discretion. <br></br><br></br>
<p style={{fontWeight:"500"}}>7. Eligibility to use as per the Indian Contract Act 1872:  </p><br></br>
(a) In order to use the Platform, you need to be 18 (eighteen) years of age or older. The Platform
may only be used or accessed by persons who can enter into and perform legally binding contracts
under the Indian Contract Act, 1872.  <br></br>
(b) The Company shall not be liable in case any false information is provided by the User, including
the User’s age, and the User and/or his natural or appointed guardian alone shall be liable for the
consequences as per the applicable law of the country to which the User belongs.  <br></br>
(c) If You are a minor, the Company disclaims all liability arising from such unauthorised use of the
platform, as well as any third-party liability arising from Your use of the platform.  <br></br><br></br>
<p style={{fontWeight:"500"}}>8. Services offered: </p> <br></br>
(a) providing, importing, exporting, selling, purchasing, trading, production, distribution,
development, customisation, or otherwise dealing in mobile applications, programs, software
packages, internet packages, web applications, products, portals, services, applications, web
designs, or other related products or services.  <br></br>
(b) manufacturing, designing, marketing, servicing, consulting, repair, altering, assembly, purchase,
sale, export, import, transfer, exchange, or otherwise dealing in all types of electrical and electronic
devices, computers, parts, or accessories, or other related products.  <br></br>
(c) establishing and/or promoting support and consulting services, whether IT-enabled or otherwise,
including but not limited to maintenance of supporting centres, solutions, R&D, IT consulting,
placement services, web services, technology process outsourcing, and acting as a consultant for the
above-mentioned services and to carry on the business of data digitalisation.  <br></br>
(d) providing management information analysis, development accounting, and business
information, analysis, and data to corporations, institutions, and individuals in India and abroad in
order to carry on the business of gathering, compiling, distributing, selling, and providing access to
information about financial operations, financial status, creditworthiness, and rating consumer
responses, as well as the management of businesses of all types and descriptions.  <br></br><br></br>
<p style={{fontWeight:"500"}}>9. User Roles/Obligations and Definitions: </p> <br></br>
As a condition precedent for the use of the company or mobile application at all times, the user
warrants that the said use shall not be for any purpose that is unlawful, illegal, immoral, or
otherwise prohibited by applicable laws or these terms of use. The User shall not use the website in
any manner that could damage, disable, hinder, inhibit, or impair the company's website or mobile
application, or interfere with any other party's use and enjoyment of the website. The user shall not
obtain or attempt to obtain any materials or information by any means not intended to be made
available or not provided through the website. 
Company/ Mobile Application may include data sync services, application download services, chat
support services, positioning services via GPS or triangulation technology, news groups, forums,
communities, personal web pages, calendars, and/or other message or communication facilities
designed to allow the user to communicate with Company/ Mobile Application, other Users of
Company/ Mobile Application, or the general public (collectively referred to as "Communication
Facilities"). The user agrees to use the communication services only to post, send, and receive
messages and materials that are appropriate, allowed, and permissible under the present terms of use
and do not violate or offend any applicable laws and these terms of use or privacy policy. 
The user warrants and undertakes to immediately report any misuse, misappropriation, unauthorised
access, unauthorised usage, theft, or any such incidents that the user is aware of in relation to
company-name/mobile application, and failure to do so shall render the user solely responsible and
liable for any consequences arising out of or in relation to the same. 
The User agrees to access and use Company-name/Mobile Application in strict accordance with
these Terms of Use and all applicable laws at all times. 
The User further agrees that he/she shall host, display, upload, modify, publish, transmit, update, or
share any information that:  <br></br>
(a) belongs to another person or user and to which the User does not have any right;  <br></br>
(b) is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic,
libellous, invasive of another’s privacy, hateful, or racially or ethnically objectionable; disparaging;
relating to or encouraging money laundering or gambling; or otherwise unlawful in any manner
whatsoever;  <br></br>
(c) Harms minors in any way <br></br>(d) Infringes any patent, trademark, copyright, or other proprietary
rights; <br></br>(e) Violates any law for the time being in force; <br></br>(f) Deceives or misleads the addressee about
the origin of such messages or communicates any information which is grossly offensive in nature; <br></br>
(g) Impersonate another person;(h) Contains software viruses or any other computer code, files, or
programmes designed to interrupt, destroy or limit the functionality of any computer resource; <br></br>(i)
Threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with
foreign states, or public order or causes incitement to the commission of any cognizable offence or
prevents investigation of any offence or is insulting to any other nation; <br></br>(j) Is opposed to the law of
land of the nation where the User resides. <br></br><br></br>
<p style={{fontWeight:"500"}}>10. Merchant Obligations:</p> <br></br>
Your use of the website and the mobile application is at your own risk. Everything on the Website
and the mobile application, including access to a listing of and information about any Suppliers, is
provided to you on an "as is" basis and without warranty of any kind, either expressed or implied,
including, but not limited to, the implied warranties of merchantability, fitness for a particular
purpose, and non-infringement. The entire risk as to the results and performance obtained from
using the content is assumed by you. 
The Suppliers and other suppliers providing services listed on the website or mobile application are
independent contractors who obtain services from Indotechs and are not agents or employees of
Indotechs. Indotechs is not liable for the acts, errors, omissions, representations, warranties,
breaches, or negligence of any such third parties, or for any personal injuries, death, property
damage, damage, or other damages or expenses resulting from such acts, errors, omissions,
representations, warranties, breaches, or negligence. 
The website and the mobile application and the content contained therein could include technical or
other inaccuracies or typographical errors. You agree and acknowledge that much of the content on
the website and mobile application is provided and/or uploaded by third parties. Indotechs makes no
representations or warranties as to the completeness or accuracy of the content. Indotechs makes no
commitment to update or correct the content that appears on the Website and/or the mobile
application. 
Indotechs makes no representations about the suitability of the information, software, products, and
services contained on the Website and mobile application for any purpose, and the inclusion or
offering for sale of any products or services on the Website and mobile application does not
constitute any endorsement or recommendation of such products or services by Indotechs. All such
information, software, products, and services are provided on an "as is" basis without warranty of
any kind. Indotechs disclaims all warranties, conditions, and liability with regard to this information
(including, without limitation, photographs, a list of amenities, and descriptions of suppliers),
software, products, and services, including all implied warranties and conditions of merchantability,
fitness for a particular purpose, title, and non-infringement. 
Information is supplied on the condition that the persons receiving the same make their own
determination as to its suitability for their purposes prior to use or in connection with the making of
any decision. 
Without prejudice to the generality of the above, Indotechs does not confirm or guarantee the
accuracy of the ratings provided to the suppliers listed on the website and the mobile application,
and the same is provided solely for the convenience of the users. <br></br><br></br>
<p style={{fontWeight:"500"}}> 11. Intellectual Property Rights (IPR) </p> <br></br>
(a) All rights, titles, and interests in and to the platform and services, including all intellectual
property rights arising out of the platform and services, are owned by or otherwise lawfully licensed
by the company. Subject to compliance with these Terms, the Company grants the User a non-
exclusive, non-transferable, non-sub licensable, royalty-free, revocable, and limited licence to use
the platform and services in accordance with these Terms and its written instructions issued from
time to time.  <br></br>
(b) The user should assume that everything he or she sees or reads on the platform is protected
under the Indian Copyright Act, 1957, and other intellectual property laws of India and may not be
used except with the prior written permission of the company.  <br></br>
(c) The Company may freely use, copy, disclose, publish, display, distribute without any payment of
royalty, acknowledgment, prior consent, or any other form of restriction arising out of the User's
intellectual property rights.   <br></br>
(d) Except as stated in these Terms, nothing in these Terms should be construed as conferring any
right in or licence to the Company’s or any third party’s intellectual property rights.  <br></br>
(e) The contents of this platform, including but not limited to the text and images herein and their
arrangements, unless otherwise noted, are copyright-protected in the whole and every part of this
platform, and the same belong to the company and may not be used, sold, licensed, copied, or
reproduced in whole or in part in any manner or form, or in or on any media, by any person without
the prior written consent of the company. <br></br><br></br>
<p style={{fontWeight:"500"}}>12. IPR in User Content: </p> <br></br>
Indotechs shall exclusively retain all worldwide rights, titles, and interests in and to the Indotechs
Services (including without limitation ownership of all intellectual property rights therein and to the
Processing Mechanism enabled by Indotechs and software related thereto). You understand and
agree that the customisations, if any, carried out for and on behalf of you shall be the intellectual
property rights of Indotechs and that you can only use such additional modifications or amendments
during the term of the engagement.<br></br><br></br>
<p style={{fontWeight:"500"}}>13. Third Party Consent:</p> <br></br> (a) You agree that the company and any third-party service providers it
uses may collect and use your information, technical data, and related information in accordance
with its privacy policy.
(b) The company may use information and data pertaining to your use of the services for analytics,
trend identification, and purposes of statistics to further enhance the effectiveness and efficiency of
the platform.  <br></br>
(c) Subject to applicable laws, the Company may be directed by law enforcement agencies, the
government, and related bodies to disclose data about Users in connection with criminal
proceedings. You understand and agree that in such instances, the company shall have the right to
share such data with relevant agencies or bodies. <br></br><br></br>
<p style={{fontWeight:"500"}}>14. Indemnification: </p> <br></br>
You shall indemnify, defend at the Company’s option, and hold the Company, its parent companies,
subsidiaries, affiliates, and their officers, associates, successors, assigns, licensors, employees,
directors, agents, and representatives harmless from and against any claim, demand, lawsuit,
judicial proceeding, losses, liabilities, damages, and costs (including, without limitation, all
damages, liabilities, settlements, costs, and attorneys’ fees) due to or arising out of your access to
the Services, your use of the Services, your violation of these Terms, or any infringement by any
third party who may use your account with the Company, or any  <br></br><br></br>
<p style={{fontWeight:"500"}}>15. Disclaimers, Warranties, and Limitation of Liability: </p><br></br>
 The company’s website or mobile application cannot exclude or limit our liability to the user where
it would be illegal to do so; this includes any liability for the gross negligence, fraud, or intentional
misconduct of the company or the other companies that are parties to providing the services. In
countries where the following types of exclusions are not permitted, Company-name is liable to the
User only for losses and damages that are a reasonably foreseeable result of our failure to exercise
reasonable care and skill or of our breach of our contract with you.This paragraph does not affect
consumer rights that cannot be waived or limited by any contract or agreement. 
In countries where exclusions or limitations of liability are permitted, the User agrees that the
Company/Mobile Application will not be liable for:  <br></br>(a) any loss of use; interruption of business; or
any direct, indirect, special, incidental, exemplary, punitive, or consequential damages of any kind
(including lost profits), regardless of the form of action, whether in contract, tort (including
negligence), strict product liability, or otherwise, arising out of the use or inadvertence of the
Company/Mobile Application. <br></br>
(b) Except for the types of liability that cannot be limited by law (as described in this section),
Company-name limits its total liability for any claim arising out of or relating to these Terms or our
Services, regardless of the form of action, to the amount paid by the user to use our services or Rs.
3500, whichever is greater. <br></br><br></br>
<p style={{fontWeight:"500"}}>16. Compliance with Laws: </p> <br></br>
(a) These Terms and Conditions shall be governed by and construed in accordance with the laws of
India.  <br></br>
(b) Any difference, dispute, conflict, or controversy, including any question as to its existence,
validity, or termination, arising out of or in connection with these Terms and Conditions ("Dispute")
shall, if not amicably settled within 30 (thirty) days after the receipt by one party of a written notice
from the other party of the existence of such Dispute, be referred to and resolved in accordance with
the Arbitration and Conciliation Act, 1996, by a sole arbitrator duly appointed by Indotechs. The
seat and venue of arbitration shall be New Delhi, and the arbitration shall be conducted in English.
The arbitrator's decision is final and binding on the parties. Each party shall bear its own cost of
arbitration.  <br></br>
(c) The Courts at New Delhi shall have sole and exclusive jurisdiction over any matters related to
these Terms and Conditions. <br></br><br></br>
<p style={{fontWeight:"500"}}>17. Report Abuse:</p>  <br></br>
The user can report any behaviour that they consider to be a violation of the Terms of Service and
Policy. Violations may include spam, malware, violence, hate speech, terrorist content,
impersonation, illegal activities, etc. 
Reports do not guarantee the removal of content or files. Content that may seem inappropriate is not
always a violation of terms of service and policy. 
Actions we may take on abusive material  <br></br>
 (a): remove the file from the account  <br></br>
(b) Restrict sharing of a file.  <br></br>
(c) limiting who can view the file.  <br></br>
(d) Disable access to one or more products.  <br></br>
(e) Delete the account.  <br></br>
(f) Report illegal materials to appropriate law enforcement authorities. <br></br><br></br>
<p style={{fontWeight:"500"}}>18. Notices: </p> <br></br>
All notices, requests, demands, and determinations for the Company under these Terms (other than
routine operational communications) shall be sent to (mention web address) The User agrees that
with respect to any written notice which the Company is required or permitted to give to the User in
connection with these Terms of Use, such notice shall be deemed to have been effectively given
immediately upon the electronic transmission of such notice to the email address of the User set
forth in their account information. 
Except as otherwise expressly provided herein, all notices and communications to be addressed to
the company or mobile application with regard to these Terms of Use or the accompanying Privacy
Policy shall be in writing and addressed to the following address: <br></br><br></br>
<p style={{fontWeight:"500"}}>19. Disputes and Governing Law:</p>  <br></br>
These Terms shall be governed by and construed and enforced in accordance with the laws of India.
Subject to other provisions in this clause, the courts in Bangalore shall have exclusive jurisdiction
over all issues arising out of these terms or the use of the services. 
Any controversies, conflicts, disputes, or differences arising out of these Terms shall be resolved by
arbitration in Delhi in accordance with the Arbitration and Conciliation Act, 1996, for the time
being in force, which is deemed to be incorporated by reference in this Clause. The tribunal shall
consist of one arbitrator appointed by the company. The language of the arbitration shall be
English. 
The parties to the arbitration shall keep the arbitration confidential and not disclose it to anyone
other than on a need-to-know basis or to legal advisors unless required to do so by law. The
decision of the arbitrator shall be final and binding on all the parties hereto. 
Each party to the arbitration shall bear its own costs with respect to any dispute. <br></br><br></br>
<p style={{fontWeight:"500"}}>20. Copyright Policy: </p> <br></br>
The User should assume that everything the User sees or reads on the Platform is protected under
the Indian Copyright Act, 1957 and other intellectual property laws of India and may not be used
except with the prior written permission of the Company. 
The contents of this Platform, including but not limited to the text and images herein and their
arrangements, unless otherwise noted, are copyright-protected in the whole and every part of this
Platform and the same belongs to the Company and may not be used, sold, licensed, copied or
reproduced in whole or in part in any manner or form or in or on any media to any person without
the prior written consent of the Company. <br></br><br></br>
<p style={{fontWeight:"500"}}>21. Claims regarding content other than copyright infringement: </p> <br></br>
(a) Any claim shall be reported at info@indotechs.in.  <br></br>
(b) Takedown of the content shall be done by Indotechs after receiving any feedback from both or
more parties.  <br></br>
(c) Indotechs/EnBill is indemnified from any legal proceedings by all parties.<br></br><br></br>
<p style={{fontWeight:"500"}}>22. Notice:</p> <br></br>
All notices, requests, demands, and determinations for the Company under these Terms (other than
routine operational communications) shall be sent to (mention web address).<br></br><br></br>
<p style={{fontWeight:"500"}}>23. Counter-Notice: </p> <br></br>
In the event that Company-namer removes any content, personal information, or profile of any User
or deactivates, disables, or terminates any Accused User’s account due to suspicion of copyright
infringement on the basis of information received from the User as above, the Accused User may
dispute his/her responsibility for or otherwise the alleged infringement itself by sending a written
communication by email or mail to the designated agent of Company-names below. 
[info@indotechs.in] 
That written communication must include the following:  <br></br>
(a) accused User's physical or electronic signature;  <br></br>(b) identification of the material that has been
removed or to which access has been disabled and the location at which the material appeared
before it was removed or access to it was disabled;  <br></br>(c) a statement under penalty of perjury that the
Accused User has good faith belief that the material was removed or disabled as a result of mistake
or misidentification of the material to be removed or disabled; and  <br></br>(d) accused User's name,
address, and telephone number, and a statement that the User consents to his/her submission to the
jurisdiction of the competent Court as provided for hereunder and that the User will accept service
of process from the person who provided notification of copyright infringement or an agent of such
person. 
The user must send all notices under the above copyright infringement policies by email or mail to
the following designated email address or agent of company-name for receipt of notifications of
claimed infringement:
</p>
                </div>


            </div>

        </div>
        <FooterLandingPage></FooterLandingPage>
        </>
    )
}
export default TermsOfUse;