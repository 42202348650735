import Header from "../components/Haeder";
import FooterLandingPage from "../components/FooterLandingPage";

const PrivacyPolicy = () => {
    return (
        <>
            <Header></Header>
            <div className="row" style={{ backgroundColor: "white" }}>
                <div className="col">
                    <p style={{ color: "#172026", font: "normal normal 600 30px/60px Source Serif Pro" ,marginTop:"5%"}}>Privacy Policy</p>
                    <div style={{textAlign:"left",padding:"5%"}}>
                         <p>
                        Indotechs Software Private Limited owns and operates this policy through its ENBILL mobile
                        application and website.<br></br><br></br>
                        <p style={{fontWeight:"500"}}>A. GENERAL</p>
                        In the course of using this website or mobile application in the name of ENBILL or availing of
                        the products and services, the company may become privy to the personal information of its
                        users, including information of a confidential nature. At Indotechs Software Pvt. Ltd., we
                        understand the importance of protecting your privacy. Our Privacy Policy covers our treatment
                        of personal information collected when anyone is on our website, www.indotechs.in, and our
                        mobile application, ENBILL, or uses the products and services we provide; it neither addresses
                        nor applies to individuals or third-party practises over which we have no control. This includes,
                        but is not limited to, cookies or embeds, which may be used by our advertisers.
                        Indotechs only collects information relevant and necessary for providing the services it offers;
                        as such, our stated goal is to customise your experience and let you get the most out of what we
                        offer. To that end: <br></br>1. For your security and privacy, your personal information is protected with
                        a password created by you and shall not be made public without your consent.<br></br>
                        2. You will always have access to your personal information, and you may edit or delete it at
                        any time.<br></br>
                        3. The company name and mobile application are sensitive to any unsupervised use of the
                        website or mobile application by children under the age of 18. If you are under the age of 18
                        years, you may use our website or mobile application only with the involvement of a parent or
                        guardian.<br></br><br></br>
                        <p style={{fontWeight:"500"}}>B. INFORMATION COLLECTED</p>
                        Indotechs/EnBill collects information related to you when you directly provide it to us or through
                        third-party sources. During the use of the website and mobile application, we may collect and
                        process information from the users, including: <br></br>1. information that the users provide to Indotechs or
                        Enbill by filling in forms on the website or mobile application. This includes contact information
                        such as name, email address, mailing address, phone number, financial information, bank account
                        number, password, and preferences information such as favourites lists and transaction history;<br></br>
                        2. information that the users provide when the users write directly to Indotechs or Enbill (including
                        by way of email, WhatsApp, or messages);<br></br>
                        3. information that the users provide to Indotechs or Enbill by completing surveys;<br></br>
                        4. Indotechs Software Pvt. Ltd. may also use certain kinds of technology, such as cookies and Web
                        beacons, to collect information. Among other things, the use of cookies and web beacons enables
                        the company to improve its services and products by identifying the areas and features that are most
                        popular and by counting the number of computers, mobile phones, or other devices accessing
                        Indotechs’ website or mobile application in the name of ENBILL, personalising and improving the
                        experience, recording preferences, and allowing the user to visit www.indotechs.in without re-
                        entering his/her member ID and/or password each time.<br></br>
                        5. A company may also collect the following information:<br></br>
                        the internet protocol (IP) address used to connect your computer to the internet;<br></br>
                        b. login, e-mail address, and password;<br></br>
                        c. geo-tracking information;<br></br>
                        d. device metrics such as when a device is in use, application usage, connectivity data, and any
                        errors or event failures;<br></br>
                        e. service metrics (e.g., the occurrences of technical errors, your interactions with service features
                        and content, your settings preferences and backup information, the location of your device running
                        an application, information about uploaded images and files such as the file name, dates, times, and
                        location of your images;<br></br>
                        f. version and time zone settings;<br></br>
                        g. purchase and content use history, which we sometimes aggregate with similar information from
                        other customers to create features like trending Arti logs;<br></br>
                        h. the full Uniform Resource Locator (URL) clickstream to, through, and from our websites,
                        including date and time; products and content you viewed or searched for; page response times,
                        download errors, length of visits to certain pages, and page interaction information (such as
                        scrolling, clicks, and mouse-overs).<br></br>
                        i. Phone numbers used to call our customer service number;<br></br><br></br>
                       <p style={{fontWeight:"500"}}> C. DISCLOSURE OF PERSONAL INFORMATION</p>
                        Indotechs/EnBill pledges that it will not sell or rent users’ personal details to anyone. We will
                        protect every bit of the users’ business or personal information and maintain the confidentiality of
                        the same. With this seal of trust, Indotechs/EnBill makes its services available to the users for
                        assessment and analysis, which include credit and behaviour scoring and market and product
                        analysis.<br></br>
                        1. Indotechs/ EnBill guarantees that it is going to keep all information confidential except in the
                        following cases:<br></br>
                        a. Indotechs/EnBill may disclose users' information to governmental and other statutory bodies with
                        appropriate authorisation to access such information for any specific legal purposes.<br></br>
                        b. Indotechs/EnBill may disclose users’ information if it is under a duty to do so in order to comply
                        with any legal obligation, or in order to enforce or apply the Terms of Use (displayed on the
                        website), or to protect the rights, property, or safety of Indotechs, its users, or others. This includes
                        exchanging information with other companies or agencies that work for fraud prevention and credit
                        reference.<br></br>
                        c. Indotechs/EnBill may disclose users’ information to its agents under a strict code of
                        confidentiality.<br></br>
                        d. Indotechs/EnBill may disclose users’ information to such third parties to whom it transfers its
                        rights and duties under the customer agreement entered into with the users. In such an event, the
                        said third parties' use of the information will be subject to such confidentiality obligations as
                        contained in this Policy.<br></br>
                        e. In the event that Indotechs sells or buys any business or assets, it may disclose the users’
                        information to the prospective seller or buyer of such business or assets. User, email, and visitor
                        information are generally one of the transferred business assets in these types of transactions.
                        Indotechs/EnBill may also transfer or assign such information in the course of corporate
                        divestitures, mergers, or dissolution.<br></br>
                        f. Indotechs may disclose users’ information to any member of its related or group companies,
                        including its subsidiaries, its ultimate holding company, and its subsidiaries, as the case may be.<br></br>
                        2. Indotechs/EnBill will never sell or rent the personal information of its clients to anyone, at any
                        time, for any reason. Indotechs/ EnBill may use the user's personal information in the following
                        ways, via. Monitor, improve and administer the website/ Mobile Application and improve the
                        quality of services <br></br>b. Analyse how the website/ Mobile Application is used, diagnose service or
                        technical problems, maintain security;<br></br> c. Remember information to help the user effectively access
                        the website / Mobile Application; <br></br>d. Monitor aggregate metrics such as total number of views,
                        visitors, traffic and demographic patterns;<br></br> e. To confirm the user's identity in order to determine its
                        eligibility to use the website/ Mobile Application and avail of the services;<br></br>f. To notify the user
                        about changes to the website / Mobile Application;<br></br> g. To enable Indotechs/ EnBill to comply with
                        its legal and regulatory obligations; <br></br>h. To help the user apply for certain products and services.
                        <br></br> i. For the purpose of sending administrative notices, service-related alerts, and other similar
                        communications with a view to optimising the efficiency of the website/mobile Mobile
                        Application.<br></br>
                        j. Doing market research, troubleshooting, protection against error, project planning, fraud, and
                        other criminal activity.<br></br>
                        k. To reinforce Indotech/EnBill Terms of Use.<br></br><br></br>
                        <p style={{fontWeight:"500"}}>D. YOUR ACCEPTANCE OF THIS POLICY</p>
                        All data and information provided by the subscriber through its use of the services is subject to the
                        Privacy Policy. By using the services, the subscriber accepts and agrees to be bound and abide by
                        the privacy policy. All data and information provided by Customer through its use of the service is
                        subject to the provider’s privacy policy, as amended from time to time, which can be viewed by
                        clicking the "Privacy" hypertext link located within the service. By using the service, the customer
                        accepts and agrees to be bound by such a privacy policy. By using the Service, you agree that you
                        have read, understood, and agree to the data collection, use, and disclosure provisions set forth in
                        the Privacy Policy.<br></br><br></br>
                        <p style={{fontWeight:"500"}}>E. Your rights</p>
                        The users shall have the right to make amendments to their data, to delete data, to review the
                        information that the company holds, and other rights.<br></br><br></br>
                        <p style={{fontWeight:"500"}}>F. collection of information by third parties</p>
                        company does not host some of the content displayed on our services. Users have the ability to post
                        content that is actually hosted by a third party but is embedded in our pages (an embed). When you
                        interact with an embed, it can send information about your interaction to the hosting third party in
                        the same way that you would if you visited the third party's site directly. The company does not
                        control what information third parties collect through embeds or what they do with the information.
                        This Privacy Policy does not apply to information collected through embeds. The privacy policy
                        belonging to the third party hosting the embed applies to any information the embed collects, and
                        we recommend you review that policy before interacting with the embed.<br></br><br></br>
                       <p style={{fontWeight:"500"}}> G. Contact Us: </p>Please direct any questions or concerns to info@indotechs.in OR K-37A, Basement,
                        Kailash Colony, Near Kailash Colony Metro Station, New Delhi-110048. </p>
                    </div>
                   
                </div>

            </div>
            <FooterLandingPage></FooterLandingPage>
        </>
    )
}
export default PrivacyPolicy;