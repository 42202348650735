import CryptoJS from "crypto-js";

class Helper{
  static environment =1;
  static base_url =null;

  static getUrl=()=>{
    if(Helper.environment===0){
      // Helper.base_url= "http://192.168.1.28:8001"
        // Helper.base_url= "http://192.168.1.34:8001"
        Helper.base_url= "http://192.168.1.28:8000"
        // Helper.base_url= "http://159.89.166.21:8000"

    }else if( Helper.environment===1){
        Helper.base_url="https://enbill.site"
    }else{
        Helper.base_url=""
    }
    return Helper.base_url
  }
  static encypt_decrypt = (encryptedData) =>{
    var key ='f^Wd2qv8z+gQa%$['//key used in Python
    key = CryptoJS.enc.Utf8.parse(key); 
    var decrypted = CryptoJS.AES.decrypt(encryptedData, key,{mode:CryptoJS.mode.ECB});
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    }
}

export default Helper