import axios from 'axios';
import Helper from "../Util/Helper";

import React, { useEffect, useState } from "react";
// import { Document, Page, pdfjs } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const BillDetail = ({ newUrl, setNewUrl }) => {

    const pathUrl = window.location.pathname;

    var baseUrl
    const countPage = window.location.pathname.slice(5, 6);

    const [getPdf, setGetPdf] = useState(null);

    if (pathUrl.includes('/0/')) {
        setNewUrl(false);
        baseUrl = window.location.pathname.slice(7, 50);
    } else if (pathUrl.includes('/1/')) {
        setNewUrl(false);
        baseUrl = window.location.pathname.slice(7, 50);
    } else if (pathUrl.includes('/2/')) {
        setNewUrl(false);
        baseUrl = window.location.pathname.slice(7, 50);
    } else if (pathUrl.includes('/3/')) {
        setNewUrl(false);
        baseUrl = window.location.pathname.slice(7, 50);
    } else {
        setNewUrl(true);
        baseUrl = window.location.pathname.slice(5, 50);
    }



    console.log("new url", newUrl)




    const getCounter = () => {
        // const getCounterRequest = {
        //     method: "GET",
        //     url: Helper.getUrl() + `${(pathUrl.includes('/0/') || pathUrl.includes('/1/') || pathUrl.includes('/2/') || pathUrl.includes('/3/')) ? `/v13/vouchers/web_customer_bill_details_v9_new/?count=${countPage}&search=${baseUrl}&web=1` : `/v13/vouchers/web_customer_bill_details_v9_new/?search=${baseUrl}&web=1`}`,
        //     headers: { "Content-Type": "application/json" },
        // };
        // axios(getCounterRequest)
        //     .then((response) => {
        //         if (response.data.code === 200) {
        //             console.log("response", response.data.data.bill_link)
        //             setGetPdf(response.data.data.bill_link)
        //         }
        //     })
        //     .catch((error) => console.log(error))

        const userAgent = navigator.userAgent; 
        if (userAgent.indexOf('Android') > -1) {
            window.location.replace('https://play.google.com/store/apps/details?id=com.indotechsj.userapp');
        } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
            window.location.replace('https://apps.apple.com/in/app/enbill/id1659215655');
        }
    }
    useEffect(() => {
        getCounter()
    }, [])

    return (
        <>

            {getPdf &&





                <> 
                {/* <div className='row d-lg-none d-block'>
                    <div className='col' style={{ position: "relative" }}>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
                            <Viewer fileUrl={getPdf && getPdf} />

                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", backgroundColor: "#008C53", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", boxShadow: "rgba(0, 0, 0, 0.9) 0px 3px 8px", height: "10%", color: "white", position: "fixed", width: "100%", left: "0", bottom: "0", zIndex: "99999" }} >
                                <div style={{ paddingLeft: "5px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", height: "35px", width: "35px", borderRadius: "50%", backgroundColor: "white" }}>
                                    <img style={{ height: "25px", width: "25px" }} src={require("../Assets/Images/MerchEnbillLogo.png")} />
                                </div>

                                <div style={{ marginLeft: "10px", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <p style={{ fontSize: "1em", color: "white", marginTop: "15px" }}>Check your Bill records here</p>
                                </div>
                                <div>
                                    <button style={{ marginLeft: "25px", color: "#008C53", border: "none", textDecoration: "none", backgroundColor: "white", color: "white", padding: "5px 10px", borderRadius: "20px" }} >
                                        <a style={{ textDecoration: "none", color: "#008C53", fontSize: "1rem" }} href="https://play.google.com/store/apps/details?id=com.indotechsj.userapp">Open app</a>
                                    </button>
                                </div>

                            </div>
                        </Worker>

                    </div>

                </div> */}
                 <div className='row d-lg-none d-block'>
                    <div className='col' style={{ position: "relative" }}>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
                            <Viewer fileUrl={getPdf && getPdf} />

                            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", textAlign: "center", backgroundColor: "#008C53", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", boxShadow: "rgba(0, 0, 0, 0.9) 0px 3px 8px", height: "10%", color: "white", position: "fixed", width: "100%", left: "0", bottom: "0", zIndex: "99999" }} >
                                <div style={{marginLeft:"10px", paddingLeft: "5px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", height: "35px", width: "35px", borderRadius: "50%", backgroundColor: "white" }}>
                                    <img style={{ height: "25px", width: "25px" }} src={require("../Assets/Images/MerchEnbillLogo.png")} />
                                </div>
                               
                                <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <p style={{ fontSize: "1em", color: "white", marginTop: "15px" }}>Check your Bill records here</p>
                                </div>
                                <div >
                                    <button style={{  color: "#008C53", border: "none", textDecoration: "none", backgroundColor: "white", color: "white", padding: "5px 10px", borderRadius: "20px" }} >
                                        <a style={{ textDecoration: "none", color: "#008C53", fontSize: "1rem" }} href="https://play.google.com/store/apps/details?id=com.indotechsj.userapp">Open app</a>
                                    </button>
                                </div>

                            </div>
                        </Worker>

                    </div>

                </div>

                    <div className='row d-none d-md-block'>
                        <div className='col' style={{ position: "relative" }}>
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
                                <Viewer fileUrl={getPdf && getPdf} />

                                <div style={{ padding: "20px", display: "flex", justifyContent: "space-around", alignItems: "center", textAlign: "center", backgroundColor: "#008C53", borderTopLeftRadius: "30px", borderTopRightRadius: "30px", boxShadow: "rgba(0, 0, 0, 0.9) 0px 3px 8px", height: "10%", color: "white", position: "fixed", width: "100%", left: "0", bottom: "0", zIndex: "99999" }} >
                                    <div style={{ paddingLeft: "5px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", height: "3vw", width: "3vw", borderRadius: "50%", backgroundColor: "white" }}>
                                        <img style={{ height: "2vw", width: "2vw" }} src={require("../Assets/Images/MerchEnbillLogo.png")} />
                                    </div>

                                    <div style={{ marginLeft: "10px", textAlign: "left", display: "flex", justifyContent: "start", alignItems: "center" }}>
                                        <p style={{ fontSize: "2vw", color: "white", marginTop: "15px" }}>Check your Bill records here</p>
                                    </div>
                                    <div>
                                        <button style={{ marginLeft: "25px", color: "#008C53", border: "none", textDecoration: "none", backgroundColor: "white", color: "white", padding: "8px 20px", borderRadius: "20px" }} >
                                            <a style={{ textDecoration: "none", color: "#008C53", fontSize: "1rem" }} href="https://play.google.com/store/apps/details?id=com.indotechsj.userapp">Open app</a>
                                        </button>
                                    </div>

                                </div>
                            </Worker>

                        </div>

                    </div>

                </>
            }

        </>
    )
}



export default BillDetail