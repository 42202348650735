import './Component.css'
import { useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';


const Header = () => {
    const { pathname } = useLocation();
    let navigate = useNavigate();
    const [drop, setDrop] = useState(false);

    const [y, setY] = useState(window.scrollY);

    const [z, setZ] = useState(false)

    const [headerTop, setHeaderTop] = useState(true);

    const [showNextHeader, setShowNextHeader] = useState(false)


    const handleNavigation = useCallback(
        e => {

            const window = e.currentTarget;
            if (y > window.scrollY) {
                setHeaderTop(true)
            } else if (y < window.scrollY) {
                setHeaderTop(false)
                setDrop(false)
            }
            if(y > 20) {
                setShowNextHeader(true)
            } else {
                setShowNextHeader (false)
            }
            setY(window.scrollY);
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    console.log("y", z);

    return (
        <>
            <nav id='heading'  className={`navbar navbar-expand-lg navbar-dark px-lg-5 px-2 row ${headerTop ? "headertop" : "headerbottom"}`}
                style={{  display: showNextHeader ? "flex" : "none", transition: "all 0.5s", zIndex: "9999999", width: "100%", margin: "0", position: "fixed", backgroundColor: "rgb(0, 106, 78)" }}>
                <div class="container-fluid">
                    <a class="navbar-brand px-3" href="/" style={{ backgroundColor: "white", borderRadius: "30px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "30px", padding: "2px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }} >
                        <img src={require("../Assets/Images/logoenbil.png")} />
                        <span style={{ color: "#EE7A37", fontWeight: "500" }}>EnBill</span>
                    </a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0 " style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                            <li className={`nav-item px-3  ${pathname === '/EnbillMerchantPage' || pathname === '/EnbillUserPage' ? "activeList" : ""}`} style={{

                            }}>
                                <div class="dropdown show">
                                    <a href="#" data-toggle="dropdown" style={{ textDecoration: "none" }}>
                                        <li className="nav-item px-3 " style={{
                                        }}>
                                            <a style={{}} className="nav-link" href="/EnbillCareerPage">SERVICES</a>
                                        </li>
                                    </a>


                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", marginTop: "20px", zIndex: "9999", paddingTop: "0", paddingBottom: "0", }}>
                                        <a className={`dropdown-item headerlist  ${pathname === '/EnbillMerchantPage' ? "activeDropMerchant" : ""}`} href="/EnbillMerchantPage" style={{ textAlign: "center" }}>Merchant</a>
                                        <a className={`dropdown-item headerlist  ${pathname === '/EnbillUserPage' ? "activeDropUser" : ""}`} href="/EnbillUserPage" style={{ textAlign: "center" }}>User</a>
                                    </div>
                                </div>
                            </li>

                            <li className={`nav-item px-3  ${pathname === '/ContactUs' ? "activeList" : ""}`} style={{

                            }}>
                                <a style={{}} className={(pathname === '/ContactUs') ? 'active' : 'nav-link'} href="/ContactUs">CONTACT US</a>
                            </li>
                            <li className={`nav-item px-3 d-block d-lg-none  ${pathname === '/AboutUs' ? "activeList" : ""}`} style={{

                            }}>
                                <div class="dropdown show">
                                    <a href="#" data-toggle="dropdown" style={{ textDecoration: "none" }}>
                                        <li style={{

                                        }}>
                                            <a style={{ textDecoration: "none", fontWeight: "600", fontSize: "20px" }} className="nav-link" href="/EnbillCareerPage">SIGN IN</a>
                                        </li>
                                    </a>


                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", marginTop: "20px", zIndex: "9999", paddingTop: "0", paddingBottom: "0", }}>
                                        <a class="dropdown-item headerlist " target="_blank" href="https://merchant.enbill.in/">MERCHANT SIGN IN</a>
                                        <a class="dropdown-item headerlist " target="_blank" href="http://ios.enbill.co/">CUSTOMER SIGN IN</a>

                                    </div>
                                </div>
                            </li>
                            <li class="nav-item px-3 d-none d-lg-block" style={{
                                position: "absolute", right: "5%"
                            }}>
                                <div class="dropdown show">
                                    <a href="#" data-toggle="dropdown" style={{ textDecoration: "none" }}>
                                        <li style={{

                                        }}>
                                            <a style={{ textDecoration: "none", fontWeight: "600", fontSize: "20px" }} className="nav-link" href="/EnbillCareerPage">SIGN IN</a>
                                        </li>
                                    </a>


                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", marginTop: "20px", zIndex: "9999", paddingTop: "0", paddingBottom: "0" }}>
                                        <a class="dropdown-item headerlist " target="_blank" href="https://merchant.enbill.in/">MERCHANT SIGN IN</a>
                                        <a class="dropdown-item headerlist " target="_blank" href="http://ios.enbill.co/">CUSTOMER SIGN IN</a>
                                    </div>
                                </div>


                            </li>
                        </ul>

                    </div>
                </div>
            </nav>

            <nav id='heading' className={`navbar navbar-expand-lg navbar-dark px-lg-5 px-2 row `}
                style={{ display: showNextHeader ? "none" : "flex", transition: "all 0.5s", zIndex: "9999999", width: "100%", margin: "0", position: "fixed", backgroundColor: "rgb(0, 106, 78)" }}>
                <div class="container-fluid">
                    <a class="navbar-brand px-3" href="/" style={{ backgroundColor: "white", borderRadius: "30px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "30px", padding: "2px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }} >
                        <img src={require("../Assets/Images/logoenbil.png")} />
                        <span style={{ color: "#EE7A37", fontWeight: "500" }}>EnBill</span>
                    </a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0 " style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                            <li className={`nav-item px-3  ${pathname === '/EnbillMerchantPage' || pathname === '/EnbillUserPage' ? "activeList" : ""}`} style={{

                            }}>
                                <div class="dropdown show">
                                    <a href="#" data-toggle="dropdown" style={{ textDecoration: "none" }}>
                                        <li className="nav-item px-3 " style={{
                                        }}>
                                            <a style={{}} className="nav-link" href="/EnbillCareerPage">SERVICES</a>
                                        </li>
                                    </a>


                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", marginTop: "20px", zIndex: "9999", paddingTop: "0", paddingBottom: "0", }}>
                                        <a className={`dropdown-item headerlist  ${pathname === '/EnbillMerchantPage' ? "activeDropMerchant" : ""}`} href="/EnbillMerchantPage" style={{ textAlign: "center" }}>Merchant</a>
                                        <a className={`dropdown-item headerlist  ${pathname === '/EnbillUserPage' ? "activeDropUser" : ""}`} href="/EnbillUserPage" style={{ textAlign: "center" }}>User</a>
                                    </div>
                                </div>
                            </li>
                            <li className={`nav-item px-3  ${pathname === '/ContactUs' ? "activeList" : ""}`} style={{

                            }}>
                                <a style={{}} className={(pathname === '/ContactUs') ? 'active' : 'nav-link'} href="/ContactUs">CONTACT US</a>
                            </li>
                            <li className={`nav-item px-3 d-block d-lg-none  ${pathname === '/AboutUs' ? "activeList" : ""}`} style={{

                            }}>
                                <div class="dropdown show">
                                    <a href="#" data-toggle="dropdown" style={{ textDecoration: "none" }}>
                                        <li style={{

                                        }}>
                                            <a style={{ textDecoration: "none", fontWeight: "600", fontSize: "20px" }} className="nav-link" href="/EnbillCareerPage">SIGN IN</a>
                                        </li>
                                    </a>


                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", marginTop: "20px", zIndex: "9999", paddingTop: "0", paddingBottom: "0", }}>
                                        <a class="dropdown-item headerlist " target="_blank" href="https://merchant.enbill.in/">MERCHANT SIGN IN</a>
                                        <a class="dropdown-item headerlist " target="_blank" href="http://ios.enbill.co/">CUSTOMER SIGN IN</a>

                                    </div>
                                </div>
                            </li>
                            <li class="nav-item px-3 d-none d-lg-block" style={{
                                position: "absolute", right: "5%"
                            }}>
                                <div class="dropdown show">
                                    <a href="#" data-toggle="dropdown" style={{ textDecoration: "none" }}>
                                        <li style={{

                                        }}>
                                            <a style={{ textDecoration: "none", fontWeight: "600", fontSize: "20px" }} className="nav-link" href="/EnbillCareerPage">SIGN IN</a>
                                        </li>
                                    </a>


                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", marginTop: "20px", zIndex: "9999", paddingTop: "0", paddingBottom: "0" }}>
                                        <a class="dropdown-item headerlist " target="_blank" href="https://merchant.enbill.in/">MERCHANT SIGN IN</a>
                                        <a class="dropdown-item headerlist " target="_blank" href="http://ios.enbill.co/">CUSTOMER SIGN IN</a>
                                    </div>
                                </div>


                            </li>
                        </ul>

                    </div>
                </div>
            </nav>
        </>
    )
}
export default Header;