import React, { useEffect, useState } from 'react';
import './App.css';
import EnbillLandingPage from './pages/EnbillLandingPage';
import EnbillMerchantPage from './pages/EnbillMerchantPage';
import EnbillUserPage from './pages/EnbillUserPage';
import EnbillCareerPage from './pages/EnbillCareerPage';
import AboutUs from './pages/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import ContactUs from './pages/ContactUs';
import TermsOfUse from './pages/TermsOfUse';
import BillDetail from './pages/BillDetail';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "@fontsource/baloo-2";

const App = () => {
  
// const billDetail = localStorage.getItem("url")
  // const billIdUrl = window.location.path.slice(1,50)
  const[error,setError]=useState(false);
  useEffect(()=>{
    
  },[]);

  const[newUrl,setNewUrl]=useState(false);
 

  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={< EnbillLandingPage/>}></Route>
          <Route exact path='/EnbillMerchantPage' element={< EnbillMerchantPage />}></Route>
          <Route exact path='/EnbillUserPage' element={< EnbillUserPage />}></Route>
          <Route exact path='/EnbillCareerPage' element={< EnbillCareerPage />}></Route>
          <Route exact path='/AboutUs' element={< AboutUs />}></Route>
          <Route exact path='/ContactUs' element={< ContactUs />}></Route>
          <Route exact path='/PrivacyPolicy' element={< PrivacyPolicy />}></Route>
          <Route exact path='/TermsOfUse' element={< TermsOfUse />}></Route>
          {/* <Route exact path='/pdf/:billid/:count/' element={< BillDetail />}></Route> */}

          <Route exact path='/bill'  element={< BillDetail newUrl={newUrl} setNewUrl={setNewUrl} />}></Route>
          <Route exact path='/pdf/:billid' element={< BillDetail newUrl={newUrl} setNewUrl={setNewUrl} />}></Route>
          
          {/* <Route exact path='/pdf/:count/:billid'  element={< BillDetail newUrl={newUrl} setNewUrl={setNewUrl} />}></Route>
          <Route exact path='/pdf/:billid' element={< BillDetail newUrl={newUrl} setNewUrl={setNewUrl} />}></Route> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
