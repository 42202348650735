import Header from "../components/Haeder";
import FooterLandingPage from "../components/FooterLandingPage";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './Career.css';
import { useState, useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";


const AboutUs = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    }
    const [rispara, setRispara] = useState(false);
    const [rakpara, setRakpara] = useState(false);

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, [])
    return (
        <>
         <Header></Header>
            <div className="row" style={{
                padding: "5%",
                backgroundColor: "white",
                width: "100%"
            }}>
                <div className="col-lg-7 mt-5 " data-Aos="fade-right" style={{ padding: "5%", margin: "auto", display: "flex", flexDirection: "column", justifyContent: "baseline", textAlign: "center" }}>
                    <p data-Aos="zoom-in" style={{ color: "#2b2b2b", font: "normal normal normal 20px/30px Karla" }}>

                        EnBill is a retail start-up, focusing not only on supporting
                        merchants to organise their businesses financially but also
                        reconnecting them with their local communities. With a
                        vision to digitally connect the small merchants of India to the
                        fast-paced youth of the country, Enbill allows eradication of
                        the hassle of Udhaar tracking, payment reconciliations,
                        handwritten accounts, poor word-of-mouth promotions, and
                        much more. While the app is a definite boon for the
                        merchant, it is a blessing in disguise for customers too, in
                        search of local products or those in a crunch of time. EnBill
                        and its many features have a socially obligated vision to
                        bring local Grahaks back to local Dukan.
                    </p>

                </div>
                <div className="col-lg-5 hide_when_small" data-Aos="fade-left" style={{ }}>
                    <div style={{ position: "relative", marginLeft: "25%", height: "350px", width: "300px", borderTop: "4px solid white", borderLeft: "4px solid white", borderBottom: "4px solid #FE5858", borderRight: "4px solid #FE5858", marginTop: "80px" }}>
                        <div style={{ position: "absolute", backgroundColor: "white", border: "6px solid #FE585840", borderRadius: "16px", padding: "5%", right: "-10%", top: "-10%", boxShadow: "0px 31px 26px #00000029" }}>
                            <img className="img-fluid" style={{}} src={require("../Assets/Images/aboutus1.png")} />
                        </div>
                        <div style={{ position: "absolute", backgroundColor: "white", border: "6px solid #FE585840", borderRadius: "16px", padding: "5%", left: "-20%", bottom: "-10%", boxShadow: "0px 31px 26px #00000029", marginBottom: "10px" }}>
                            <img className="img-fluid" style={{}} src={require("../Assets/Images/aboutus2.png")} />
                        </div>
                    </div>

                </div>
            </div>
            
            <div className="row" style={{ width: "100%" }}>
                <div className="col">
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                        <p style={{ color: "#160637", font: "normal normal medium 29px/31px Poppins", fontSize: "29px", marginRight: "5px" }}>Core</p>

                        <p style={{ color: "#EE7A37", font: "normal normal medium 29px/31px Poppins", fontSize: "29px" }}>Team</p>
                    </div>
                    <div className="col">
                        <div className="gd-carousel-wrapper">

                            <Carousel


                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                                swipeable={true}
                                draggable={true}
                                autoPlay={true}
                                showDots={false}
                                ssr={true}
                                renderDotsOutside={true}
                                arrows={true}
                                infinite={true}
                                autoPlaySpeed={3000}


                                responsive={responsive}

                            >
                                <div className="col pcard" style={{
                                    border: "1px dashed #EE7A37", boxShadow: "0px 28px 62px #00000026", margin: "50px ",
                           
                                    minWidth: "200px", borderRadius: "10px", height: "90%"
                                }}>
                                    <div className="cardtop" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                        <div style={{ borderRadius: "10px", display: "flex", justifyContent: "center", padding: "10%" }}>
                                            <img className="img-fluid rounded" style={{ marginLeft: "30%", marginRight: "30%" }} src={require("../Assets/Images/partnerimg3.png")} />

                                        </div>

                                    </div>
                                    <div className="desc" style={{ borderRadius: "10px", textAlign: "center", padding: "10%" }}>
                                        <p>Pankaj Khetan</p>
                                        <p style={{ color: "#6C6C6C", font: " normal normal normal 18px/29px Poppins" }}>He has rich experience of 30+ years in the field of all types
                                            of finance and taxation. An Insolvency professional with
                                            hands-on experience in debt restructuring and business
                                            takeovers.</p>

                                    </div>
                                </div>
                                <div className="col pcard" style={{
                                    border: "1px dashed #EE7A37", boxShadow: "0px 28px 62px #00000026", margin: "50px ",

                                    minWidth: "200px", borderRadius: "10px", height: "90%"
                                }}>

                                    <div className="cardtop" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                        <div style={{ display: "flex", justifyContent: "center", padding: "10%" }}>
                                            <img className="img-fluid rounded" style={{ marginLeft: "30%", marginRight: "30%" }} src={require("../Assets/Images/partnerimg1.png")} />

                                        </div>

                                    </div>
                                    <div className="desc" style={{ textAlign: "center", padding: "10%" }}>
                                        <p>Rachna Khetan</p>
                                        <p style={{ color: "#6C6C6C", font: " normal normal normal 18px/29px Poppins" }}>She has rich experience of 25+ years in the fields of
                                            business and as advisory role to the top management. An
                                            Expert in creating value through financial engineering and
                                            restructuring.</p>

                                    </div>
                                </div>

                                <div className="col pcard" style={{
                                    border: "1px dashed #EE7A37", boxShadow: "0px 28px 62px #00000026", margin: "50px ",
                                    //  maxWidth: "250px",
                                    minWidth: "200px", borderRadius: "10px", height: "90%"
                                }}>
                                    <div className="cardtop" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                        <div style={{ display: "flex", justifyContent: "center", padding: "10%" }}>
                                            <img className="img-fluid rounded" style={{ marginLeft: "30%", marginRight: "30%" }} src={require("../Assets/Images/partnerimg2.png")} />

                                        </div>

                                    </div>
                                    <div className="desc" style={{ textAlign: "center", padding: "10%" }}>
                                        <p>Rakesh Kumar Raizada</p>
                                        <p style={{ color: "#6C6C6C", font: " normal normal normal 18px/29px Poppins" }}>He has rich experience of 30+ years in the field of sales and
                                            marketing, has mentored various projects in retail and ed
                                            tech. While working in Europe (1998–2001) and India,   {rakpara ? <p>he
                                                developed his expertise in sales, marketing, project
                                                handling, Salesforce, and other SaaS-based products.</p> : null} </p>

                                        <p onClick={() => setRakpara(!rakpara)} style={{ cursor: "pointer", color: "#EE7A37", font: "normal normal medium 14px/35px Poppins" }}>{rakpara ? <p>Read Less</p> : <p>Read More</p>}</p>
                                    </div>
                                </div>

                                <div className="col pcard" style={{
                                    border: "1px dashed #EE7A37", boxShadow: "0px 28px 62px #00000026", margin: "50px ",
                                    //  maxWidth: "250px", 
                                    minWidth: "200px", borderRadius: "10px", height: "90%"
                                }}>
                                    <div className="cardtop" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                        <div style={{ display: "flex", justifyContent: "center", padding: "10%" }}>
                                            <img className="img-fluid rounded" style={{ marginLeft: "30%", marginRight: "30%",height:"148px",width:"148px" }} src={require("../Assets/Images/rishabh3.png")} />

                                        </div>
                                    </div>
                                    <div className="desc" style={{ textAlign: "center", padding: "10%" }}>
                                        <p>Rishabh Kumar Raizada</p>
                                        <p style={{ color: "#6C6C6C", font: " normal normal normal 18px/29px Poppins" }}>
                                            Gaming bug bit him at age 6, in the form game called "RISE OF THE NATIONS's" which helped him to develop analytical thinking, problem solving, attention to detail, and leadership skills.
                                            {rispara ? <p>His interest and passion for the game made him interested in "HOW IT WORKS" which led him to learn the nuances of building software. At the age of 13, he was part of teams(double his age) developing software for various clients in India and abroad.He also worked on and delivered various complex projects for companies as a leader of a team spread across various functions.</p> : null}

                                        </p> <p onClick={() => setRispara(!rispara)} style={{ cursor: "pointer", color: "#EE7A37", font: "normal normal medium 14px/35px Poppins" }}>{rispara ? <p>Read Less</p> : <p>Read More</p>}</p>

                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>

            </div>

            <div style={{ marginBottom: "20px", width: "100%" }}>
                <div className="col" style={{ backgroundColor: "#EE7A37", display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center", padding: "1%" }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "20px" }}>
                        <div>
                            <a href="/EnbillCareerPage" style={{ border: "none", textDecoration: "none", marginTop: "-20px", borderRadius: "14px", padding: "5px 20px", color: "#EE7A37", backgroundColor: "white", font: "normal normal bold 30px/60px Roboto", display: "block " }}>Join us</a>
                        </div>
                    </div>

                </div>



            </div>
            <FooterLandingPage></FooterLandingPage>

        </>
    )
}

export default AboutUs;