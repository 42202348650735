import Header from "../components/Haeder";
import FooterLandingPage from "../components/FooterLandingPage";
import React, { useEffect, useRef, useState } from "react";

import './Career.css';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Lottie from "lottie-web";
import Aos from "aos";
import "aos/dist/aos.css";
import { useIsVisible } from 'react-is-visible'
import Helper from "../Util/Helper";

const EnbillCareerPage = () => {
    const [value, setValue] = React.useState(50);
    const navigate = useNavigate();
    const [filelink, setFileLink] = useState("")
    const [name, setName] = useState(null);
    const getName = (val) => {
        setName(val.target.value)

    }

    const [address, setAddress] = useState(null);
    const getAddress = (val) => {
        setAddress(val.target.value)

    }

    const [city, setCity] = useState(null);
    const getCity = (val) => {
        setCity(val.target.value)

    }

    const [position, setPosition] = useState(null);
    const getPosition = (val) => {
        setPosition(val.target.value)
    }

    const [phonenumber, setPhonenumber] = useState(null);
    const getPhonenumber = (event) => {
        setPhonenumber(event.target.value)

    }

    const [message, setMessage] = useState(null);
    const getMessage = (val) => {
        setMessage(val.target.value)

    }

    const [email, setEmail] = useState(null);
    const getEmail = (val) => {
        setEmail(val.target.value)

    }
    const [file, setFile] = useState("");


    const handleChange = (event) => {

        setFile(event.target.files[0])


    }


    function handleSubmit(phonenumber) {
        if (name === null) {
            alert("Please enter your name")
        } else if (phonenumber === null) {
            alert("Please enter your number")
        } else if (email === null) {
            alert("Please enter your email")
        } else if (file == "") {
            alert("Please upload your cv")
        }
        else {
            const url = Helper.getUrl() + `/v13/items/get_enbill_in_file/?phone=${phonenumber}`;
            const formData = new FormData();

            formData.append('file', file);
            formData.append('fileName', file.name);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };


            axios.post(url, formData, config).then((response) => {
                console.log(response.data);
                if (response.data.code === 200) {

                    setFileLink(response.data.data.link)

                } else {
                    console.log("errorrr")
                }
            });
        }
    }

    useEffect(() => {
        var ebody = 'Name:%20' + name + '%0D%0A' +  'Position:%20' + position + '%0D%0A' + 'Phone Number:%20' + phonenumber + '%0D%0A' + 'Email:%20' + email + '%0D%0A' + 'File:%20' + filelink + '%0D%0A' + 'Message:%20' + message;


        if (filelink && filelink != null) {
            if (name === null) {
                alert("Please enter your name")
            } else if (phonenumber === null) {
                alert("Please enter your number")
            } else if (email === null) {
                alert("Please enter your email")
            } else if (file == "") {
                alert("Please upload your cv")
            }
            else {
                var add = address;
                window.location.href = "mailto: hr@enbill.in?body=" + ebody;
            }
        }
    }, [filelink])
    const nodeRef = useRef()
    const isVisible = useIsVisible(nodeRef)
    const container4 = useRef(null);

    useEffect(() => {
        Lottie.loadAnimation({
            container: container4.current,
            renderer: "svg",
            loop: false,
            autoplay: true,
            animationData: require("../Assets/Images/BillsAnimatioln.json")
        });
        return () => {
            Lottie.destroy();
        };
    }, [isVisible])

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, [])


    return (

        <>
            <Header></Header>

            <div className="row" style={{
                padding: "5%",
                backgroundColor: "white",
                width: "100%"
            }}>
                <div className="col" data-aos="zoom-in" style={{ textAlign: "center", marginTop: "50px" }}>
                    <div style={{ textAlign: "left", marginLeft: "8%" }}>
                        <p className="mt-3" style={{ font: "normal normal 600 50px/60px Source Serif Pro" }}>“Choose a job you love, and you will never have to work a day
                            in your life." —Confucius</p>

                    </div>


                </div>
            </div>
            <div className="row px-5 px-md-0" style={{
                width: "100%", marginTop: "10px",
                backgroundColor: "white",
                display: "flex", justifyContent: "center", paddingBottom: "2%"
            }}>
                <div style={{ textAlign: "left", padding: "1% 5%" }}>
                    <p style={{ color: "#EE7A37", font: "normal normal medium 35px/90px Roboto", fontSize: "35px", fontWeight: "600" }}>Current</p>
                    <p style={{ color: "#7A7878", font: "normal normal medium 25px/90px Roboto", fontSize: "25px", fontWeight: "600" }}>Opening</p>
                </div>
                <div className="col-md-2" style={{ minHeight: "200px", backgroundColor: "white", margin: "1%", textAlign: "center", borderRadius: "9px", boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px", padding: "1%",paddingBottom:"20px" }}>
                    <div className="careercard">
                        <h4 style={{ color: "#002147", marginTop: "5%" }}>Android Developer</h4>
                    </div>

                    <div>
                        <p style={{ color: "#7A7878", marginTop: "8%", font: "normal normal 600 16px/48px Source Serif Pro" }}>Full-time</p>
                    </div>
                    <div>
                        <a href="#targetname" className="apply-btn" style={{ textDecoration: "none", marginTop: "5%", border: "1px solid #EE7A378A", borderRadius: "22px", padding: "5px 10px", boxShadow: "0px 3px 6px #00000029", marginBottom: "10px" }}>Apply Now</a>

                    </div>


                </div>
                <div className="col-md-2" style={{ minHeight: "200px", backgroundColor: "white", margin: "1%", textAlign: "center", borderRadius: "9px", boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px", padding: "1%",paddingBottom:"20px" }}>
                    <div className="careercard">
                        <h4 style={{ color: "#002147", marginTop: "5%" }}>IOS Developer</h4>
                    </div>

                    <div>
                        <p style={{ color: "#7A7878", marginTop: "8%", font: "normal normal 600 16px/48px Source Serif Pro" }}>Full-time</p>
                    </div>
                    <div>
                        <a href="#targetname" className="apply-btn" style={{ textDecoration: "none", marginTop: "5%", border: "1px solid #EE7A378A", borderRadius: "22px", padding: "5px 10px", boxShadow: "0px 3px 6px #00000029", marginBottom: "10px" }}>Apply Now</a>
                    </div>



                </div>
                <div className="col-md-2" style={{ minHeight: "200px", backgroundColor: "white", margin: "1%", textAlign: "center", borderRadius: "9px", boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px", padding: "1%",paddingBottom:"20px" }}>
                    <div className="careercard">
                        <h4 style={{ color: "#002147", marginTop: "5%" }}>Web Developer</h4>
                    </div>
                    <div>
                        <p style={{ color: "#7A7878", marginTop: "8%", font: "normal normal 600 16px/48px Source Serif Pro" }}>Full-time</p>
                    </div>
                    <div>
                        <a href="#targetname" className="apply-btn" style={{ textDecoration: "none", marginTop: "5%", border: "1px solid #EE7A378A", borderRadius: "22px", padding: "5px 10px", boxShadow: "0px 3px 6px #00000029", marginBottom: "10px" }}>Apply Now</a>
                    </div>

                </div>
                <div className="col-md-2" style={{ minHeight: "200px", backgroundColor: "white", margin: "1%", textAlign: "center", borderRadius: "9px", boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px", padding: "1%",paddingBottom:"20px" }}>
                    <div className="careercard">
                        <h4 style={{ color: "#002147", marginTop: "5%" }}>React JS</h4>
                    </div>
                    <div>
                        <p style={{ color: "#7A7878", marginTop: "8%", font: "normal normal 600 16px/48px Source Serif Pro" }}>Full-time</p>
                    </div>
                    <div>
                        <a href="#targetname" className="apply-btn" style={{ textDecoration: "none", marginTop: "5%", border: "1px solid #EE7A378A", borderRadius: "22px", padding: "5px 10px", boxShadow: "0px 3px 6px #00000029", marginBottom: "10px" }}>Apply Now</a>
                    </div>

                </div>
                <div className="col-md-2" style={{ minHeight: "200px", backgroundColor: "white", margin: "1%", textAlign: "center", borderRadius: "9px", boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px", padding: "1%",paddingBottom:"20px" }}>
                    <div className="careercard">
                        <h4 style={{ color: "#002147", marginTop: "5%" }}>React Native</h4>
                    </div>
                    <div>
                        <p style={{ color: "#7A7878", marginTop: "8%", font: "normal normal 600 16px/48px Source Serif Pro" }}>Full-time</p>
                    </div>
                    <div>
                        <a href="#targetname" className="apply-btn" style={{ textDecoration: "none", marginTop: "5%", border: "1px solid #EE7A378A", borderRadius: "22px", padding: "5px 10px", boxShadow: "0px 3px 6px #00000029", marginBottom: "10px" }}>Apply Now</a>
                    </div>

                </div>
                <div className="col-md-2" style={{ minHeight: "200px", backgroundColor: "white", margin: "1%", textAlign: "center", borderRadius: "9px", boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px", padding: "1%",paddingBottom:"20px" }}>
                    <div className="careercard">
                        <h4 style={{ color: "#002147", marginTop: "5%" }}>UI/UX Designer</h4>
                    </div>
                    <div>
                        <p style={{ color: "#7A7878", marginTop: "8%", font: "normal normal 600 16px/48px Source Serif Pro" }}>Full-time</p>
                    </div>
                    <div>
                        <a href="#targetname" className="apply-btn" style={{ textDecoration: "none", marginTop: "5%", border: "1px solid #EE7A378A", borderRadius: "22px", padding: "5px 10px", boxShadow: "0px 3px 6px #00000029", marginBottom: "10px" }}>Apply Now</a>
                    </div>


                </div>
                <div className="col-md-2" style={{ minHeight: "200px", backgroundColor: "white", margin: "1%", textAlign: "center", borderRadius: "9px", boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px", padding: "1%",paddingBottom:"20px" }}>
                    <div className="careercard">
                        <h4 style={{ color: "#002147", marginTop: "5%" }}>Product Manager</h4>
                    </div>
                    <div>
                        <p style={{ color: "#7A7878", marginTop: "8%", font: "normal normal 600 16px/48px Source Serif Pro" }}>Full-time</p>
                    </div>
                    <div>
                        <a href="#targetname" className="apply-btn" style={{ textDecoration: "none", marginTop: "5%", border: "1px solid #EE7A378A", borderRadius: "22px", padding: "5px 10px", boxShadow: "0px 3px 6px #00000029", marginBottom: "10px" }}>Apply Now</a>
                    </div>


                </div>
                <div className="col-md-2" style={{ minHeight: "200px", backgroundColor: "white", margin: "1%", textAlign: "center", borderRadius: "9px", boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px", padding: "1%",paddingBottom:"20px" }}>
                    <div className="careercard">
                        <h4 style={{ color: "#002147", marginTop: "5%" }}>Video Editor</h4>
                    </div>
                    <div>
                        <p style={{ color: "#7A7878", marginTop: "8%", font: "normal normal 600 16px/48px Source Serif Pro" }}>Full-time</p>
                    </div>
                    <div>
                        <a href="#targetname" className="apply-btn" style={{ textDecoration: "none", marginTop: "5%", border: "1px solid #EE7A378A", borderRadius: "22px", padding: "5px 10px", boxShadow: "0px 3px 6px #00000029", marginBottom: "10px" }}>Apply Now</a>
                    </div>



                </div>
                <div className="col-md-2" style={{ minHeight: "200px", backgroundColor: "white", margin: "1%", textAlign: "center", borderRadius: "9px", boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px", padding: "1%",paddingBottom:"20px" }}>
                    <div className="careercard">
                        <h4 style={{ color: "#002147", marginTop: "5%" }}>HR</h4>
                    </div>
                    <div>
                        <p style={{ color: "#7A7878", marginTop: "8%", font: "normal normal 600 16px/48px Source Serif Pro" }}>Full-time</p>
                    </div>
                    <div>
                        <a href="#targetname" className="apply-btn" style={{ textDecoration: "none", marginTop: "5%", border: "1px solid #EE7A378A", borderRadius: "22px", padding: "5px 10px", boxShadow: "0px 3px 6px #00000029", marginBottom: "10px" }}>Apply Now</a>
                    </div>



                </div>
                <div className="col-md-2" style={{ minHeight: "200px", backgroundColor: "white", margin: "1%", textAlign: "center", borderRadius: "9px", boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px", padding: "1%",paddingBottom:"20px" }}>
                    <div className="careercard">
                        <h4 style={{ color: "#002147", marginTop: "5%" }}>Marketing Manager</h4>

                    </div>
                    <div>
                        <p style={{ color: "#7A7878", marginTop: "8%", font: "normal normal 600 16px/48px Source Serif Pro" }}>Full-time</p>
                    </div>
                    <div>
                        <a href="#targetname" className="apply-btn" style={{ textDecoration: "none", marginTop: "5%", border: "1px solid #EE7A378A", borderRadius: "22px", padding: "5px 10px", boxShadow: "0px 3px 6px #00000029", marginBottom: "10px" }}>Apply Now</a>
                    </div>


                </div>
            </div>

            <div className="row our-value-container d-sm-flex d-md-none" style={{  width: "100%", display: "flex", justifyContent: "center",paddingTop:"50px",paddingBottom:"30px" }}>
                <div className="col-lg col-md-12 our-values">
                    <div className="val-section">
                        <img className="img-fluid" src={require("../Assets/Images/Passion.jpg")} />
                        <div className="bg-overlay" >
                            <div className="overlay-text" style={{ fontSize:"3vw" }}>
                                We are looking for candidates who
                                are enthusiastic and passionate about what they do. Their
                                successes should shine through during the interview. People
                                who love their work often stay at companies longer than those
                                who work for the sake of the salary. Enthusiasm is an excellent
                                employee trait. Enthusiastic and outgoing employees are often
                                helpful to a business.
                            </div>
                        </div>
                    </div>
                    <p>Enthusiasm and Passion</p>
                </div>
                <div className="col-lg col-md-12 our-values">
                    <div className="val-section">
                        <img className="img-fluid" src={require("../Assets/Images/Honesty.jpg")} />
                        <div className="bg-overlay" >
                            <div className="overlay-text" style={{ fontSize:"3vw" }} >
                                We trust honest employees to do the
                                right thing and make the right decisions in the organisation's best
                                interests. Many situations in the workplace can create ethical and
                                moral dilemmas for employees.
                            </div>
                        </div>
                    </div>
                    <p>Honesty and integrity</p>
                </div>
                <div className="col-lg col-md-12 our-values">
                    <div className="val-section">
                        <img className="img-fluid" src={require("../Assets/Images/Problem.jpg")} />
                        <div className="bg-overlay" >
                            <div className="overlay-text"  style={{ fontSize:"3vw" }}>
                                Employees who work on something
                                until it's solved or complete and who bring their best efforts to
                                solving problems are considered good employees.
                            </div>
                        </div>
                    </div>
                    <p>Problem-solving abilities</p>
                </div>
                <div className="col-lg col-md-12 our-values">
                    <div className="val-section">
                        <img className="img-fluid" src={require("../Assets/Images/Team.jpg")} />
                        <div className="bg-overlay" >
                            <div className="overlay-text" style={{ fontSize:"3vw" }}>
                                Most jobs require teams to work together;
                                others need employees to interact with clients and occasional
                                outside contractors. The ability to collaborate and work pleasantly
                                and effectively with others is a key part of nearly any job. In order to
                                achieve a common goal, it is an asset to any company.
                            </div>
                        </div>
                    </div>
                    <p>Being a team player</p>
                </div>

            </div>

            <div className="row our-value-container d-md-flex d-none" style={{  width: "100%", display: "flex", justifyContent: "center",paddingTop:"50px",paddingBottom:"30px" }}>
                <div className="col-lg col-md-12 our-values">
                    <div className="val-section">
                        <img className="img-fluid" src={require("../Assets/Images/Passion.jpg")} />
                        <div className="bg-overlay" >
                            <div className="overlay-text" style={{  }}>
                                We are looking for candidates who
                                are enthusiastic and passionate about what they do. Their
                                successes should shine through during the interview. People
                                who love their work often stay at companies longer than those
                                who work for the sake of the salary. Enthusiasm is an excellent
                                employee trait. Enthusiastic and outgoing employees are often
                                helpful to a business.
                            </div>
                        </div>
                    </div>
                    <p>Enthusiasm and Passion</p>
                </div>
                <div className="col-lg col-md-12 our-values">
                    <div className="val-section">
                        <img className="img-fluid" src={require("../Assets/Images/Honesty.jpg")} />
                        <div className="bg-overlay" >
                            <div className="overlay-text" >
                                We trust honest employees to do the
                                right thing and make the right decisions in the organisation's best
                                interests. Many situations in the workplace can create ethical and
                                moral dilemmas for employees.
                            </div>
                        </div>
                    </div>
                    <p>Honesty and integrity</p>
                </div>
                <div className="col-lg col-md-12 our-values">
                    <div className="val-section">
                        <img className="img-fluid" src={require("../Assets/Images/Problem.jpg")} />
                        <div className="bg-overlay" >
                            <div className="overlay-text" >
                                Employees who work on something
                                until it's solved or complete and who bring their best efforts to
                                solving problems are considered good employees.
                            </div>
                        </div>
                    </div>
                    <p>Problem-solving abilities</p>
                </div>
                <div className="col-lg col-md-12 our-values">
                    <div className="val-section">
                        <img className="img-fluid" src={require("../Assets/Images/Team.jpg")} />
                        <div className="bg-overlay" >
                            <div className="overlay-text" style={{  }}>
                                Most jobs require teams to work together;
                                others need employees to interact with clients and occasional
                                outside contractors. The ability to collaborate and work pleasantly
                                and effectively with others is a key part of nearly any job. In order to
                                achieve a common goal, it is an asset to any company.
                            </div>
                        </div>
                    </div>
                    <p>Being a team player</p>
                </div>

            </div>

            <div className="row" style={{ width: "100%", padding: "1% 3%", width: "100%" }}>
                <div style={{ fontSize: "35px", fontWeight: "600", textAlign: "left", padding: "1px 5%" }}>
                    <p style={{ color: "#EE7A37" }}>Would you like</p>
                    <p style={{ color: "#7A7878" }}>To join us?</p>

                </div>
                <div className="col-md">

                    <div style={{ padding: "1% 5%" }}>
                        <form>
                            <input className="colorinp" onChange={getName} placeholder="Your Name*" name="name" style={{ border: "1px solid #7A7878", borderRadius: "16px", padding: "3% 5%", boxShadow: "0px 3px 6px #00000040", margin: "10px", width: "100%", }}></input>
                            <input className="colorinp" onChange={getPhonenumber} placeholder="Phone Number*" name="number" style={{ border: "1px solid #7A7878", borderRadius: "16px", padding: "3% 5%", boxShadow: "0px 3px 6px #00000040", margin: "10px", width: "100%", }}></input>
                            <input className="colorinp" onChange={getPosition} placeholder="Position" name="Position" style={{ border: "1px solid #7A7878", borderRadius: "16px", padding: "3% 5%", boxShadow: "0px 3px 6px #00000040", margin: "10px", width: "100%", }}></input>
                            <input className="colorinp" onChange={getEmail} placeholder="Your Email*" style={{ border: "1px solid #7A7878", borderRadius: "16px", padding: "3% 5%", boxShadow: "0px 3px 6px #00000040", margin: "10px", width: "100%", }}></input>
                        </form>
                    </div>


                </div>

                <div className="col-md">
                    <div style={{ padding: "1% 5%" }}>




                        <input className="colorinp" onChange={handleChange} accept="application/pdf" type="file" id="choose_file" placeholder="Your CV*" style={{ border: "1px solid #7A7878", borderRadius: "16px", padding: "3% 5%", boxShadow: "0px 3px 6px #00000040", margin: "10px", width: "100%", color: "#7A7878" }}></input>




                        <textarea
                            onChange={getMessage}
                            className="inp colorinp" id="secinp" rows="3" col="3" placeholder="message" style={{ border: "1px solid #7A7878", borderRadius: "16px", padding: "2% 5%", boxShadow: "0px 3px 6px #00000040", margin: "5px", backgroundColor: "white" }}

                        ></textarea>


                    </div>

                </div>
                <div className="row">
                    <div className="col-md-6">
                        <a onClick={() => { handleSubmit(phonenumber); }} type="submit" name="targetname" className="ms-2" style={{ display: "block", backgroundColor: "#11884F", color: "white", borderRadius: "16px", fontSize: "19px", boxShadow: "0px 3px 6px #00000040", padding: "3% 30%", textDecoration: "none" }}>
                            Submit Your CV
                        </a>

                    </div>

                </div>

            </div>
            <FooterLandingPage></FooterLandingPage>

        </>
    )
}

export default EnbillCareerPage