
import axios from "axios";
import Helper from "../Util/Helper";
import { useEffect, useState } from "react";

const FooterLandingPage = () => {
    const [billData, setBillData] = useState(0);
    const getCounter = () => {
        const getCounterRequest = {
            method: "GET",
            url:
                Helper.getUrl() + "/v11/contact/total_gmv_for_no_use/?key=10326266-65b0-11ed-9aa7-41f8d8f91c6f",
            headers: { "Content-Type": "application/json" },
        };
        axios(getCounterRequest)
            .then((response) => {
                if (response.data.code === 200) {
                    console.log("response", response.data.data)
                    setBillData(response.data.data.total_gmv)
                }
            })
            .catch((error) => console.log(error))
    }
    useEffect(() => {
        getCounter()
    }, [])

    return (
        <>

            <div className="row" style={{ padding: "1%",  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", width: "100%", marginTop: "20px" }}>

                <div className="col">
                    <p style={{ fontWeight: "600", fontSize: "16px" }}>Quick Links</p>
                    <a href="/EnbillCareerPage" style={{ display: "block", textDecoration: "none", fontSize: "16px", color: "#545D63", marginTop: "5px" }}>Career</a>
                    <a href="/ContactUs" style={{ display: "block", textDecoration: "none", fontSize: "16px", color: "#545D63", marginTop: "5px" }}>Contact us</a>
                </div>

                <div className="col">
                    <p style={{ fontWeight: "600", fontSize: "16px" }}>Download The Apps</p>
                    <a href="https://play.google.com/store/search?q=enbill&c=apps" target="_blank" style={{ display: "block", textDecoration: "none", fontSize: "16px", color: "#545D63", marginTop: "5px" }}>Playstore</a>
                    <a href="https://apps.apple.com/in/app/enbill/id1659215655" target="_blank" style={{ display: "block", textDecoration: "none", fontSize: "16px", color: "#545D63", marginTop: "5px" }}>Appstore</a>
                </div>

                <div className="col">
                    <p style={{ fontWeight: "600", fontSize: "16px" }}>Registered Office</p>
                    <p style={{ textDecoration: "none", fontSize: "16px", color: "#545D63", marginTop: "5px" }}>K37/A, Basement, Kailash Colony, New Delhi</p>
                    <p style={{ textDecoration: "none", fontSize: "16px", color: "#545D63",marginTop:"-10px" }}>hello@enbill.in</p>
                </div>
                <div className="col">
                    <p style={{ fontWeight: "600", fontSize: "16px" }}>Follow Us</p>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                        <div>
                            <a href="https://www.instagram.com/enbillbharat/" target="_blank" className="mt-4 btns">             <img style={{ padding: "5px" }} src={require("../Assets/Images/newinstalogo.png")} /></a>
                        </div>
                        <div>
                            <a href="https://www.facebook.com/EnBillBharat" target="_blank" className="mt-4 btns">   <img style={{ padding: "5px" }} src={require("../Assets/Images/newfacebooklogo.png")} /> </a>
                        </div>
                        <div>
                            <a href="https://twitter.com/Enbillbharat" target="_blank" className="mt-4 btns">  <img style={{ padding: "5px" }} src={require("../Assets/Images/newtwitterlogo.png")} /></a>
                        </div>
                        <div>
                            <a href="https://www.linkedin.com/company/enbill/mycompany/" target="_blank" className="mt-4 btns">  <img style={{ padding: "5px" }} src={require("../Assets/Images/newlinkdin.png")} /></a>
                        </div>
                    </div>


                </div>
            </div>
            <div className="row" style={{ width: "100%", padding: "10px" }}>
                <div className="col-sm-6 col-md justify-content-lg-start justify-content-center" style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                    <div>
                        <p style={{ paddingTop: "20px", width: "100%", color: "#C3CCD3", font: "normal normal medium Raleway", fontSize: "12px" }}>© Copyright@EnBill-2023-All Rights Reserved.</p>
                    </div>
                </div>
                 <div className="col-sm-6 col-md" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div>
                    <a href="/TermsOfUse" style={{ color: "#172026", marginRight: "20px" }}>Terms of Use</a>
                        <a href="/PrivacyPolicy" style={{ color: "#172026" }}>Privacy Policy</a>
                    </div>
                </div>
                <div className="col-sm-6 col-md justify-content-lg-end justify-content-center" style={{ display: "flex",  alignItems: "center" }}>
                    <div>
                    <p style={{ paddingTop: "20px", margin: "0", width: "100%", font: "normal normal medium Raleway", fontSize: "10px" }}>CIN No.: 472900DL2021PTC384966</p>
                        <p style={{ paddingTop: "10px", margin: "0", width: "100%", font: "normal normal medium Raleway", fontSize: "10px" }}>GST No.: 09AAGCI2572N1Z2</p>
                    </div>

                </div>
            </div>

        </>
    )
}
export default FooterLandingPage;