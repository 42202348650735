
import Header from "../components/Haeder";
import FooterLandingPage from "../components/FooterLandingPage";
import React, { StrictMode } from 'react';
import { useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

import { create } from '@lottiefiles/lottie-interactivity';
import Lottie from "lottie-web";
// import 'ImagesAnimation3.json'

import Marquee from "react-fast-marquee";
import axios from 'axios';
import Helper from "../Util/Helper";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const EnbillLandingPage = () => {

    const container = useRef(null);
    const [video1, setVideo1] = useState(false)
    const [video2, setVideo2] = useState(false)
    const [video3, setVideo3] = useState(false)
    const [video4, setVideo4] = useState(false)
    const [video5, setVideo5] = useState(false)

    const [vidInd1, setVidInd1] = useState(false);
    const [vidInd2, setVidInd2] = useState(false);
    const [vidInd3, setVidInd3] = useState(true);
    const [vidInd4, setVidInd4] = useState(false);
    const [vidInd5, setVidInd5] = useState(false);

    const [vid1, setVid1] = useState(4);
    const [vid2, setVid2] = useState(5);
    const [vid3, setVid3] = useState(6);
    const [vid4, setVid4] = useState(5);
    const [vid5, setVid5] = useState(4);
    const [vidHeight1, setVidHeight1] = useState(350);
    const [vidHeight2, setVidHeight2] = useState(370);
    const [vidHeight3, setVidHeight3] = useState(390);
    const [vidHeight4, setVidHeight4] = useState(370);
    const [vidHeight5, setVidHeight5] = useState(350);

    const [vidWidth1, setVidWidth1] = useState(360);
    const [vidWidth2, setVidWidth2] = useState(380);
    const [vidWidth3, setVidWidth3] = useState(400);
    const [vidWidth4, setVidWidth4] = useState(380);
    const [vidWidth5, setVidWidth5] = useState(360);

    const [vidTransform1, setVidTransform1] = useState('0.839, 0.839, 1');
    const [vidTransform2, setVidTransform2] = useState('0.9195, 0.9195, 1');
    const [vidTransform3, setVidTransform3] = useState('1, 1, 1');
    const [vidTransform4, setVidTransform4] = useState('0.9195, 0.9195, 1');
    const [vidTransform5, setVidTransform5] = useState('0.839, 0.839, 1');

    const [colorGreen, setColorGreen] = useState(false);

    const container2 = useRef(null);

    const container3 = useRef(null);

    const [chromeBrowser, setChromeBrowser] = useState(false);

    const [shopLogo, setShopLogo] = useState([]);


    useEffect(() => {
        Lottie.loadAnimation({
            container: container2.current,
            renderer: "svg",
            loop: false,
            autoplay: true,
            // animationData: require("../Assets/Images/BG Animation_11.json")
            // path: "https://enbfile.s3.ap-south-1.amazonaws.com/enbill_in/json/BG_Animation_11.json",
             path: "https://d2f6yofx7yjl70.cloudfront.net/cloud/BG_Animation_11.json",
            //path: "https://d2f6yofx7yjl70.cloudfront.net/cloud/Animationsmall.json",

        });
        return () => {
            Lottie.destroy();
        };
    }, [])

    useEffect(() => {
        Lottie.loadAnimation({
            container: container3.current,
            renderer: "svg",
            loop: false,
            autoplay: true,
            // animationData: require("../Assets/Images/Mobile Page Animationsmall.json")
            //    path: "https://enbfile.s3.ap-south-1.amazonaws.com/enbill_in/json/Mobile_Page_Animationsmall.json"
            // path: "https://d2f6yofx7yjl70.cloudfront.net/cloud/Mobile_Page_Animationsmall.json"
            path: "https://d2f6yofx7yjl70.cloudfront.net/cloud/Animationsmall.json",
        });
        return () => {
            Lottie.destroy();
        };
    }, [])

    const lottiee = React.useRef(null);

    React.useEffect(() => {
        lottiee.current.addEventListener("load", function (e) {
            create({
                mode: "scroll",
                player: "#firstLottie",
                container: '#container',
                actions: [
                    {
                        visibility: [0, 1],
                        type: "seek",
                        frames: [0, 181]

                    }
                ]
            });
        });
    }, [lottiee]);


    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, [])



    useEffect(() => {
        if (vidInd1 === true) {
            setVidHeight1(390)
            setVidHeight2(370)
            setVidHeight3(350)
            setVidHeight4(330)
            setVidHeight5(310)
            setVid1(6)
            setVid2(5)
            setVid3(4)
            setVid4(3)
            setVid5(2)
            setVidWidth1(400)
            setVidWidth2(380)
            setVidWidth3(360)
            setVidWidth4(340)
            setVidWidth5(320)

            setVidTransform1('1, 1, 1')
            setVidTransform2('0.9195, 0.9195, 1')
            setVidTransform3('0.839, 0.839, 1')
            setVidTransform4('0.7585, 0.7585, 1')
            setVidTransform5('0.678, 0.678, 1')
        } else if (vidInd2 === true) {
            setVidHeight1(370)
            setVidHeight2(390)
            setVidHeight3(370)
            setVidHeight4(350)
            setVidHeight5(330)
            setVid2(6)
            setVid1(5)
            setVid3(5)
            setVid4(4)
            setVid5(3)
            setVidWidth1(380)
            setVidWidth2(400)
            setVidWidth3(380)
            setVidWidth4(360)
            setVidWidth5(340)

            setVidTransform1('0.9195, 0.9195, 1')
            setVidTransform2('1, 1, 1')
            setVidTransform3('0.9195, 0.9195, 1')
            setVidTransform4('0.839, 0.839, 1')
            setVidTransform5('0.7585, 0.7585, 1')
        } else if (vidInd3 === true) {
            setVidHeight1(350)
            setVidHeight2(370)
            setVidHeight3(390)
            setVidHeight4(370)
            setVidHeight5(350)
            setVid3(6)
            setVid2(5)
            setVid1(4)
            setVid4(5)
            setVid5(4)
            setVidWidth1(360)
            setVidWidth2(380)
            setVidWidth3(400)
            setVidWidth4(380)
            setVidWidth5(360)

            setVidTransform1('0.839, 0.839, 1')
            setVidTransform2('0.9195, 0.9195, 1')
            setVidTransform3('1, 1, 1')
            setVidTransform4('0.9195, 0.9195, 1')
            setVidTransform5('0.839, 0.839, 1')
        } else if (vidInd4 === true) {
            setVidHeight1(330)
            setVidHeight2(350)
            setVidHeight3(370)
            setVidHeight4(390)
            setVidHeight5(370)
            setVid4(6)
            setVid1(3)
            setVid2(4)
            setVid3(5)
            setVid5(5)
            setVidWidth1(340)
            setVidWidth2(360)
            setVidWidth3(380)
            setVidWidth4(400)
            setVidWidth5(370)

            setVidTransform1('0.7585, 0.7585, 1')
            setVidTransform2('0.839, 0.839, 1')
            setVidTransform3('0.9195, 0.9195, 1')
            setVidTransform4('1, 1, 1')
            setVidTransform5('0.9195, 0.9195, 1')
        } else if (vidInd5 === true) {
            setVidHeight1(310)
            setVidHeight2(330)
            setVidHeight3(350)
            setVidHeight4(370)
            setVidHeight5(390)
            setVid5(6)
            setVid1(2)
            setVid2(3)
            setVid3(4)
            setVid4(5)
            setVidWidth1(320)
            setVidWidth2(340)
            setVidWidth3(360)
            setVidWidth4(380)
            setVidWidth5(400)

            setVidTransform1('0.678, 0.678, 1')
            setVidTransform2('0.7585, 0.7585, 1')
            setVidTransform3('0.839, 0.839, 1')
            setVidTransform4('0.9195, 0.9195, 1')
            setVidTransform5('1, 1, 1')
        }
    }, [vidInd1, vidInd2, vidInd3, vidInd4, vidInd5])



    if (video1 === true) {
        var url = "https://www.youtube.com/watch?v=L0BcJ9oR_1s"
    } else if (video2 === true) {
        var url = "https://www.youtube.com/watch?v=pNgI7Jqp8sQ"
    } else if (video3 === true) {
        var url = "https://www.youtube.com/watch?v=owMFkSCf-Gw"
    } else if (video4 === true) {
        var url = "https://www.youtube.com/watch?v=5WDb6NYy5SI&t=9s"
    } else if (video5 === true) {
        var url = "https://www.youtube.com/watch?v=L0BcJ9oR_1s"
    }

    const [bgCol, setBgCol] = useState(0);

    const handleScroll = () => {
        const scrollPosition = window.scrollY; // => scroll position
        console.log(scrollPosition);

        if (scrollPosition > 1000) {
            setColorGreen(true)
        } else if (scrollPosition < 1000) {
            setColorGreen(false)
        }

        if (scrollPosition > 700) {
            setBgCol(1)
        }
        if (scrollPosition > 1300) {
            setBgCol(2)
        }
        if (scrollPosition > 1900) {
            setBgCol(5)
        }
        if (scrollPosition > 2500) {
            setBgCol(3)
        }
        if (scrollPosition > 3200) {
            setBgCol(4)
        }

    };


    useEffect(() => {
        handleScroll();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    useEffect(() => {
        const { detect } = require('detect-browser');
        const browser = detect();
        switch (browser && browser.name) {
            case 'chrome':
                setChromeBrowser(true);
            case 'firefox':
                console.log('supported');
                break;

            case 'edge':
                console.log('kinda ok');
                break;

            default:
                console.log('not supported');
        }

    }, [])

    // const getCounter = () => {
    //     const getCounterRequest = {
    //         method: "GET",
    //         url:
    //             Helper.getUrl() + "/v13/contact/get_shoplogo",
    //         headers: { "Content-Type": "application/json" },
    //     };
    //     axios(getCounterRequest)
    //         .then((response) => {
    //             if (response.data.code === 200) {
    //                 console.log("response for shop logo", response.data.data)
    //                 setShopLogo(response.data.data)
    //             }
    //         })
    //         .catch((error) => console.log(error))
    // }
    // useEffect(() => {
    //     getCounter()
    // }, [])

    return (
        <div>

            <Header></Header>

            <div className="row d-lg-block d-none " style={{ padding: "0", width: "100%" }}>

                <div className="col firstbox" style={{ padding: "0", minHeight: "100vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                    <div ref={container2} style={{ position: "relative", width: "100%" }}>
                    </div>

                    {chromeBrowser && chromeBrowser ? <>
                        <div style={{ position: "absolute" }} data-Aos="zoom-in" data-aos-delay="400">
                            <p style={{ marginLeft: "30px", marginTop: "11vw", color: "white", font: "normal normal 900 6vw/5vw Source Serif Pro" }} >Local Dukaan </p>
                            <div style={{ textAlign: "left", marginLeft: "60px" }}>
                                <p style={{ color: "white", font: "normal normal 900 6vw/5vw Source Serif Pro" }}>Local Grahak</p>
                                <p style={{ color: "white", font: "normal normal 500 3vw/3vw Source Serif Pro" }}>Ab Dukaan </p>
                                <p style={{ color: "white", font: "normal normal 500 3vw/3vw Source Serif Pro" }}>Offline bhi, Online bhi</p>
                            </div>
                        </div></> :
                        <>
                            <div style={{ position: "absolute" }} data-Aos="zoom-in" data-aos-delay="400">
                                <p style={{ marginLeft: "30px", marginTop: "11vw", color: "white", font: "normal normal 900 5vw/5vw Source Serif Pro" }} >Local Dukaan </p>
                                <div style={{ textAlign: "left", marginLeft: "60px" }}>
                                    <p style={{ color: "white", font: "normal normal 900 5vw/5vw Source Serif Pro" }}>Local Grahak</p>
                                    <p style={{ color: "white", font: "normal normal 500 3vw/3vw Source Serif Pro" }}>Ab Dukaan </p>
                                    <p style={{ color: "white", font: "normal normal 500 3vw/3vw Source Serif Pro" }}>Offline bhi, Online bhi</p>
                                </div>
                            </div>
                        </>}
              

                </div>
            </div>
            <div className="row d-lg-block d-none " style={{ width: "100%", borderBottomRightRadius: "30px", borderBottomLeftRadius: "30px", width: "100%", height: "15vh", backgroundColor: "#006a4e" }} >

            </div>

            <div className="row d-block d-lg-none " style={{ overflow: "hidden", padding: "0", width: "100%" }}>
                <div className="col firstbox" style={{ position: "relative", minHeight: "70vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "Center" }} >
                    {chromeBrowser ?
                        <>
                            <div style={{ color: "white", fontWeight: "900", fontSize: "10vw", fontFamily: "Source Serif Pro", lineHeight: "10vw", marginTop: "-20%" }} >
                                <p>Local Dukaan</p>
                                <p>Local Grahak</p>
                            </div>
                            <div style={{ color: "white", font: "normal normal 600 5.5vw/8vw Source Serif Pro" }}>
                                <p>Ab Dukaan <br></br>Offline bhi,Online bhi</p>
                            </div>
                            <div style={{ position: "absolute", top: "48%" }} >
                                <div ref={container3} >
                                </div>
                            </div>
                        </> :
                        <>
                            <div style={{ color: "white", fontWeight: "900", fontSize: "10vw", fontFamily: "Source Serif Pro", lineHeight: "10vw" }} >
                                <p>Local Dukaan</p>
                                <p>Local Grahak</p>
                            </div>
                            <div style={{ color: "white", font: "normal normal 500 5vw/8vw Source Serif Pro" }}>
                                <p>Ab Dukaan <br></br>Offline bhi,Online bhi</p>
                            </div>
                            <div style={{ position: "absolute", top: "50%" }} >
                                <div ref={container3} >
                                </div>
                            </div>
                        </>}
                </div>
            </div>


            <div className="row d-lg-block d-none " style={{ width: "100%", marginBottom: "30px", boxSizing: "border-box", width: "100%", borderRadius: "30px" }} >
                <div className="col " data-aos="fade-in" id="container" style={{ display: "flex", height: "500vh", position: "relative" }}>
                    <div style={{ width: "40%", height: "100vh", position: "sticky", top: "0" }}>
                        <div style={{ marginTop: "100px", padding: "5%", display: "flex", flexDirection: "column" }} >
                            <div id="box1" className={`menuNav ${bgCol === 1 ? " showMenu" : ""}`} style={{ textAlign: "left", paddingLeft: "30px", paddingRight: "30px", paddingTop: "18px" }}>

                                <div>
                                    <h4 style={{ fontWeight: "600" }}>Offline to Online</h4>
                                </div>
                                <div>
                                    <p style={{ fontWeight: "300", fontSize: "1.3vw" }} >Now take your offline outlet, online and provide convenience to your customers. </p>
                                </div>

                            </div>
                            <div id="box2" className={`menuNav ${bgCol === 2 ? " showMenu" : ""}`} style={{ textAlign: "left", paddingLeft: "30px", paddingRight: "30px", paddingTop: "18px" }}>
                                <h4 style={{ fontWeight: "600" }}>Showcase your products</h4>
                                <p style={{ fontWeight: "300", fontSize: "1.3vw" }} >Create your product catalogue and showcase your product
                                    range online . </p>
                            </div>
                            <div id="box5" className={`menuNav ${bgCol === 5 ? " showMenu" : ""}`} style={{ textAlign: "left", paddingLeft: "30px", paddingRight: "30px", paddingTop: "18px" }}>
                                <h4 style={{ fontWeight: "600" }}>Orders le Offline bhi Online bhi</h4>
                                <p style={{ fontWeight: "300", fontSize: "1.3vw" }} >Take orders from your customers & delivery/pickup . </p>
                            </div>
                            <div id="box3" className={`menuNav ${bgCol === 3 ? " showMenu" : ""}`} style={{ textAlign: "left", paddingLeft: "30px", paddingRight: "30px", paddingTop: "18px" }}>
                                <h4 style={{ fontWeight: "600" }}>Connect digitally with your grahak</h4>
                                <p style={{ fontWeight: "300", fontSize: "1.3vw" }} >Communicate via SMS, notification or whatsapp messages about new products, discounts directly to your customers. </p>
                            </div>
                            <div id="box4" className={`menuNav ${bgCol === 4 ? " showMenu" : ""}`} style={{ textAlign: "left", paddingLeft: "30px", paddingRight: "30px", paddingTop: "18px" }}>
                                <h4 style={{ fontWeight: "600" }}>Shop management tools</h4>
                                <p style={{ fontWeight: "300", fontSize: "1.3vw" }} >  Keep track of all transaction & reconsile your account on your fingertips.  </p>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "60%", height: "100vh", position: "sticky", top: "0" }}>
                        <lottie-player
                            ref={lottiee}
                            id="firstLottie"
                            // src="https://enbfile.s3.ap-south-1.amazonaws.com/enbill_in/json/ImagesAnimation3.json"
                            src="https://d2f6yofx7yjl70.cloudfront.net/cloud/ImagesAnimation3.json"
                        // src="images/test.json"
                        // src={require("../../public/test.json")}
                        // src="ImagesAnimation3.json"
                        >
                        </lottie-player>
                    </div>
                </div>

            </div>
            <div className="row d-lg-none d-block" data-Aos="zoom-in" style={{ width: "100%", padding: "5%" }}>
                <div className="col-12">
                    <div>
                        {/* <img style={{ height: "290px", width: "270px" }} src={require("../Assets/Images/ScrollDashboard2.png")} /> */}
                        <LazyLoadImage
                            style={{ height: "290px", width: "270px" }}
                            effect="blur"
                            // src={require("../Assets/Images/ScrollDashboard2.png")} 
                            src="https://d2f6yofx7yjl70.cloudfront.net/cloud/ScrollDashboard2.png"
                            />
                    </div>
                    <div style={{ padding: "5%", backgroundColor: "rgba(236, 240, 241,1)" }} >
                        <h4>Offline to Online</h4>
                        <p style={{ fontWeight: "300", }} > Now take your offline outlet, online and provide convenience to your customers. </p>
                    </div>
                </div>
                <div className="col-12" >
                    <div>
                        {/* <img style={{ height: "290px", width: "270px" }} src={require("../Assets/Images/ScrollCatalogue2.png")} /> */}
                        <LazyLoadImage
                            style={{ height: "290px", width: "270px" }}
                            effect="blur"
                            // src={require("../Assets/Images/ScrollCatalogue2.png")} 
                            src="https://d2f6yofx7yjl70.cloudfront.net/cloud/ScrollCatalogue2.png"
                            />
                    </div>
                    <div style={{ padding: "5%", backgroundColor: "rgba(236, 240, 241,1)" }} >
                        <h4>Showcase your products</h4>
                        <p style={{ fontWeight: "300", }} >Create your product catalogue and showcase your product
                            range online. </p>
                    </div>
                </div>
                <div className="col-12" >
                    <div>
                        {/* <img style={{ height: "290px", width: "270px" }} src={require("../Assets/Images/merchantBooking.png")} /> */}
                        <LazyLoadImage
                            style={{ height: "290px", width: "270px" }}
                            effect="blur"
                            // src={require("../Assets/Images/merchantBooking.png")}
                            src= "https://d2f6yofx7yjl70.cloudfront.net/cloud/merchantBooking.png"
                             />
                    </div>
                    <div style={{ padding: "5%", backgroundColor: "rgba(236, 240, 241,1)" }} >
                        <h4>Orders le Offline bhi Online bhi</h4>
                        <p style={{ fontWeight: "300", }} >Take orders from your customers & delivery/pickup .</p>
                    </div>
                </div>
                <div className="col-12" >
                    <div>
                        {/* <img style={{ height: "290px", width: "270px" }} src={require("../Assets/Images/ScrollGrahak2.png")} /> */}
                        <LazyLoadImage
                            style={{ height: "290px", width: "270px" }}
                            effect="blur"
                            // src={require("../Assets/Images/ScrollGrahak2.png")} />
                            src="https://d2f6yofx7yjl70.cloudfront.net/cloud/ScrollGrahak2.png"/>
                    </div>
                    <div style={{ padding: "5%", backgroundColor: "rgba(236, 240, 241,1)" }} >
                        <h4>Connect digitally with your grahak</h4>
                        <p style={{ fontWeight: "300", }} >Communicate via SMS, notification or whatsapp messages about new products, discounts directly to your customers.</p>
                    </div>
                </div>

                <div className="col-12" >
                    <div>
                        {/* <img style={{ height: "290px", width: "270px" }} src={require("../Assets/Images/ScrollGalla2.png")} /> */}
                        <LazyLoadImage
                            style={{ height: "290px", width: "270px" }}
                            effect="blur"
                            // src={require("../Assets/Images/ScrollGalla2.png")}
                            src="https://d2f6yofx7yjl70.cloudfront.net/cloud/ScrollGalla2.png"
                             />
                    </div>
                    <div style={{ padding: "5%", backgroundColor: "rgba(236, 240, 241,1)" }} >
                        <h4>Shop management tools</h4>
                        <p style={{ fontWeight: "300", }} >
                            Keep track of all transaction & reconsile your account on your fingertips. </p>
                    </div>
                </div>

            </div>






            <div className="row d-lg-block d-none" style={{ width: "100%", borderRadius: "20px 20px 0 0", padding: "5%", height: "500px" }}>
                <div className="col" data-Aos="zoom-in" style={{ padding: "", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className={`transformStyle `}
                        onMouseEnter={() => { setVidInd1(true); setVidInd2(false); setVidInd3(false); setVidInd4(false); setVidInd5(false) }}
                        style={{ transform: `scale3d(${vidTransform1})`, zIndex: `${vid1}`, margin: "0", height: `${vidHeight1}px`, width: `${vidWidth1}px`, borderRadius: "36px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", position: "relative" }}>
                        <div style={{ display: !vidInd1 ? "block" : "none", width: "100%", height: "100%", backgroundColor: 'rgba(0,0,0,0.6)', zIndex: "2", top: "0", left: '0', right: '0', bottom: '0', borderRadius: "36px", position: "absolute" }}></div>
                        <img onClick={() => { setVideo1(true); setVideo2(false); setVideo3(false); setVideo4(false); setVideo5(false) }} className={`img-fluid videos ytvideos`} data-toggle="modal" data-target="#exampleModal" style={{ width: "100%", height: "100%", borderRadius: "36px", top: "0", left: '0', right: '0', bottom: '0', position: "absolute" }} 
                        // src={require("../Assets/Images/videoW1.jpg")} 
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/videoW1.png"
                        />

                        <div style={{ position: "absolute", top: "40%", zIndex: "99999", left: "43%" }}>
                            {/* <img style={{ height: "80px", width: "80px" }} src={require("../Assets/Images/playbtn.png")} /> */}
                            <LazyLoadImage
                                style={{ height: "80px", width: "80px" }}
                                effect="blur"
                                // src={require("../Assets/Images/playbtn.png")} />
                                src="https://d2f6yofx7yjl70.cloudfront.net/cloud/playbtn.png"/>

                        </div>
                    </div>
                    <div className={`transformStyle `}
                        onMouseEnter={() => { setVidInd1(false); setVidInd2(true); setVidInd3(false); setVidInd4(false); setVidInd5(false) }} style={{ transform: `scale3d(${vidTransform2})`, zIndex: `${vid2}`, marginLeft: "-10%", padding: "0", height: `${vidHeight2}px`, width: `${vidWidth2}px`, borderRadius: "36px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", position: "relative" }}>
                        <div style={{ display: !vidInd2 ? "block" : "none", width: "100%", height: "100%", backgroundColor: 'rgba(0,0,0,0.6)', zIndex: "2", top: "0", left: '0', right: '0', bottom: '0', borderRadius: "36px", position: "absolute" }}></div>
                        <img onClick={() => { setVideo1(false); setVideo2(true); setVideo3(false); setVideo4(false); setVideo5(false) }} className={`img-fluid videos ytvideos`} data-toggle="modal" data-target="#exampleModal" style={{ width: "100%", height: "100%", borderRadius: "36px", top: "0", left: '0', right: '0', bottom: '0', position: "absolute" }} 
                        // src={require("../Assets/Images/videoW2.jpg")} 
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/videoW2.png"
                        />

                        <div style={{ position: "absolute", top: "40%", zIndex: "99999", left: "43%" }}>
                            {/* <img style={{ height: "80px", width: "80px" }} src={require("../Assets/Images/playbtn.png")} /> */}
                            <LazyLoadImage
                                style={{ height: "80px", width: "80px" }}
                                effect="blur"
                                // src={require("../Assets/Images/playbtn.png")} />
                                src="https://d2f6yofx7yjl70.cloudfront.net/cloud/playbtn.png"/>

                        </div>
                    </div>
                    <div className={`transformStyle `}
                        onMouseEnter={() => { setVidInd1(false); setVidInd2(false); setVidInd3(true); setVidInd4(false); setVidInd5(false) }} style={{ position: "relative", transform: `scale3d(${vidTransform3})`, zIndex: `${vid3}`, marginLeft: "-10%", padding: "0", height: `${vidHeight3}px`, width: `${vidWidth3}px`, borderRadius: "36px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", position: "relative" }}>
                        <div style={{ display: !vidInd3 ? "block" : "none", width: "100%", height: "100%", backgroundColor: 'rgba(0,0,0,0.6)', zIndex: "2", top: "0", left: '0', right: '0', bottom: '0', borderRadius: "36px", position: "absolute" }}></div>
                        <img onClick={() => { setVideo1(false); setVideo2(false); setVideo3(true); setVideo4(false); setVideo5(false) }} className={`img-fluid videos ytvideos`} data-toggle="modal" data-target="#exampleModal" style={{ width: "100%", height: "100%", borderRadius: "36px", top: "0", left: '0', right: '0', bottom: '0', position: "absolute" }} 
                        // src={require("../Assets/Images/videoW3.jpg")}
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/videoW3.png"
                         />

                        <div style={{ position: "absolute", top: "40%", zIndex: "99999", left: "43%" }}>
                            {/* <img style={{ height: "80px", width: "80px" }} src={require("../Assets/Images/playbtn.png")} /> */}
                            <LazyLoadImage
                                style={{ height: "80px", width: "80px" }}
                                effect="blur"
                                // src={require("../Assets/Images/playbtn.png")} />
                                src="https://d2f6yofx7yjl70.cloudfront.net/cloud/playbtn.png"/>

                        </div>
                    </div>
                    <div className={`transformStyle`}
                        onMouseEnter={() => { setVidInd1(false); setVidInd2(false); setVidInd3(false); setVidInd4(true); setVidInd5(false) }} style={{ transform: `scale3d(${vidTransform4})`, zIndex: `${vid4}`, marginLeft: "-10%", padding: "0", height: `${vidHeight4}px`, width: `${vidWidth4}px`, borderRadius: "36px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", position: "relative" }} >
                        <div style={{ display: !vidInd4 ? "block" : "none", width: "100%", height: "100%", backgroundColor: 'rgba(0,0,0,0.6)', zIndex: "2", top: "0", left: '0', right: '0', bottom: '0', borderRadius: "36px", position: "absolute" }}></div>
                        <img onClick={() => { setVideo1(false); setVideo2(false); setVideo3(false); setVideo4(true); setVideo5(false) }} className={`img-fluid videos ytvideos`} data-toggle="modal" data-target="#exampleModal" style={{ width: "100%", height: "100%", borderRadius: "36px", top: "0", left: '0', right: '0', bottom: '0', position: "absolute" }} 
                        // src={require("../Assets/Images/videoW4.jpg")}
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/videoW4.png"
                         />


                        <div style={{ position: "absolute", top: "40%", zIndex: "99999", left: "43%" }}>
                            {/* <img style={{ height: "80px", width: "80px" }} src={require("../Assets/Images/playbtn.png")} /> */}
                            <LazyLoadImage
                                style={{ height: "80px", width: "80px" }}
                                effect="blur"
                                // src={require("../Assets/Images/playbtn.png")} />
                                src="https://d2f6yofx7yjl70.cloudfront.net/cloud/playbtn.png"/>

                        </div>
                    </div>
                    <div className={`transformStyle `}
                        onMouseEnter={() => { setVidInd5(true); setVidInd1(false); setVidInd2(false); setVidInd3(false); setVidInd4(false) }} style={{ transform: `scale3d(${vidTransform5})`, zIndex: `${vid5}`, marginLeft: "-10%", padding: "0", height: `${vidHeight5}px`, width: `${vidWidth5}px`, borderRadius: "36px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", position: "relative" }}>
                        <div style={{ display: !vidInd5 ? "block" : "none", width: "100%", height: "100%", backgroundColor: 'rgba(0,0,0,0.6)', zIndex: "2", top: "0", left: '0', right: '0', bottom: '0', borderRadius: "36px", position: "absolute" }}></div>
                        <img onClick={() => { setVideo1(false); setVideo2(false); setVideo3(false); setVideo4(false); setVideo5(true) }} className={`img-fluid videos ytvideos`} data-toggle="modal" data-target="#exampleModal" style={{ width: "100%", height: "100%", borderRadius: "36px", top: "0", left: '0', right: '0', bottom: '0', position: "absolute" }}
                        //  src={require("../Assets/Images/videoW5.jpg")}
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/videoW5.png"
                          />

                        <div style={{ position: "absolute", top: "40%", zIndex: "99999", left: "43%" }}>
                            {/* <img style={{ height: "80px", width: "80px" }} src={require("../Assets/Images/playbtn.png")} /> */}
                            <LazyLoadImage
                                style={{ height: "80px", width: "80px" }}
                                effect="blur"
                                // src={require("../Assets/Images/playbtn.png")} />
                                src="https://d2f6yofx7yjl70.cloudfront.net/cloud/playbtn.png"/>

                        </div>
                    </div>
                </div>
            </div>

            <div className="row d-lg-none d-block" style={{ width: "100%", borderRadius: "20px 20px 0 0", padding: "15% 0% 0% 0%", height: "400px" }}>
                <div className="col" style={{ padding: "", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className={`transformStyle `}
                        onMouseEnter={() => { setVidInd1(true); setVidInd2(false); setVidInd3(false); setVidInd4(false); setVidInd5(false) }}
                        style={{ transform: `scale3d(${vidTransform1})`, zIndex: `${vid1}`, margin: "0", height: `${vidHeight1 - 100}px`, width: `${vidWidth1}px`, borderRadius: "36px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", position: "relative" }}>
                        <div style={{ display: !vidInd1 ? "block" : "none", width: "100%", height: "100%", backgroundColor: 'rgba(0,0,0,0.6)', zIndex: "2", top: "0", left: '0', right: '0', bottom: '0', borderRadius: "36px", position: "absolute" }}></div>
                        <img onClick={() => { setVideo1(true); setVideo2(false); setVideo3(false); setVideo4(false); setVideo5(false) }} className={`img-fluid videos ytvideos`} data-toggle="modal" data-target="#exampleModal" style={{ width: "100%", height: "100%", borderRadius: "36px", top: "0", left: '0', right: '0', bottom: '0', position: "absolute" }}
                        //  src={require("../Assets/Images/LandingVid1.png")} 
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/LandingVid1.png"
                         />

                    </div>
                    <div className={`transformStyle `}
                        onMouseEnter={() => { setVidInd1(false); setVidInd2(true); setVidInd3(false); setVidInd4(false); setVidInd5(false) }} style={{ transform: `scale3d(${vidTransform2})`, zIndex: `${vid2}`, marginLeft: "-50%", padding: "0", height: `${vidHeight2 - 100}px`, width: `${vidWidth2}px`, borderRadius: "36px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", position: "relative" }}>
                        <div style={{ display: !vidInd2 ? "block" : "none", width: "100%", height: "100%", backgroundColor: 'rgba(0,0,0,0.6)', zIndex: "2", top: "0", left: '0', right: '0', bottom: '0', borderRadius: "36px", position: "absolute" }}></div>
                        <img onClick={() => { setVideo1(false); setVideo2(true); setVideo3(false); setVideo4(false); setVideo5(false) }} className={`img-fluid videos ytvideos`} data-toggle="modal" data-target="#exampleModal" style={{ width: "100%", height: "100%", borderRadius: "36px", top: "0", left: '0', right: '0', bottom: '0', position: "absolute" }} 
                        // src={require("../Assets/Images/LandingVid3.png")} 
                        src= "https://d2f6yofx7yjl70.cloudfront.net/cloud/LandingVid3.png"
                        />
                    </div>
                    <div className={`transformStyle `}
                        onMouseEnter={() => { setVidInd1(false); setVidInd2(false); setVidInd3(true); setVidInd4(false); setVidInd5(false) }} style={{ transform: `scale3d(${vidTransform3})`, zIndex: `${vid3}`, marginLeft: "-50%", padding: "0", height: `${vidHeight3 - 100}px`, width: `${vidWidth3}px`, borderRadius: "36px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", position: "relative" }}>
                        <div style={{ display: !vidInd3 ? "block" : "none", width: "100%", height: "100%", backgroundColor: 'rgba(0,0,0,0.6)', zIndex: "2", top: "0", left: '0', right: '0', bottom: '0', borderRadius: "36px", position: "absolute" }}></div>
                        <img onClick={() => { setVideo1(false); setVideo2(false); setVideo3(true); setVideo4(false); setVideo5(false) }} className={`img-fluid videos ytvideos`} data-toggle="modal" data-target="#exampleModal" style={{ width: "100%", height: "100%", borderRadius: "36px", top: "0", left: '0', right: '0', bottom: '0', position: "absolute" }}
                        //  src={require("../Assets/Images/LandingVid2.png")}
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/LandingVid2.png"
                          />
                    </div>
                    <div className={`transformStyle`}
                        onMouseEnter={() => { setVidInd1(false); setVidInd2(false); setVidInd3(false); setVidInd4(true); setVidInd5(false) }} style={{ transform: `scale3d(${vidTransform4})`, zIndex: `${vid4}`, marginLeft: "-50%", padding: "0", height: `${vidHeight4 - 100}px`, width: `${vidWidth4}px`, borderRadius: "36px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", position: "relative" }} >
                        <div style={{ display: !vidInd4 ? "block" : "none", width: "100%", height: "100%", backgroundColor: 'rgba(0,0,0,0.6)', zIndex: "2", top: "0", left: '0', right: '0', bottom: '0', borderRadius: "36px", position: "absolute" }}></div>
                        <img onClick={() => { setVideo1(false); setVideo2(false); setVideo3(false); setVideo4(true); setVideo5(false) }} className={`img-fluid videos ytvideos`} data-toggle="modal" data-target="#exampleModal" style={{ width: "100%", height: "100%", borderRadius: "36px", top: "0", left: '0', right: '0', bottom: '0', position: "absolute" }} 
                        // src={require("../Assets/Images/LandingVid4.png")}
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/LandingVid4.png"
                         />
                    </div>
                    <div className={`transformStyle `}
                        onMouseEnter={() => { setVidInd5(true); setVidInd1(false); setVidInd2(false); setVidInd3(false); setVidInd4(false) }} style={{ transform: `scale3d(${vidTransform5})`, zIndex: `${vid5}`, marginLeft: "-50%", padding: "0", height: `${vidHeight5 - 100}px`, width: `${vidWidth5}px`, borderRadius: "36px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", position: "relative" }}>
                        <div style={{ display: !vidInd5 ? "block" : "none", width: "100%", height: "100%", backgroundColor: 'rgba(0,0,0,0.6)', zIndex: "2", top: "0", left: '0', right: '0', bottom: '0', borderRadius: "36px", position: "absolute" }}></div>
                        <img onClick={() => { setVideo1(false); setVideo2(false); setVideo3(false); setVideo4(false); setVideo5(true) }} className={`img-fluid videos ytvideos`} data-toggle="modal" data-target="#exampleModal" style={{ width: "100%", height: "100%", borderRadius: "36px", top: "0", left: '0', right: '0', bottom: '0', position: "absolute" }}
                        //  src={require("../Assets/Images/LandingVid5.png")} 
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/LandingVid5.png"
                         />
                    </div>
                </div>
            </div>
            <div className="row" style={{ height: "40px" }} >

            </div>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" style={{ display: "flex", justifyContent: "center" }}>
                    <div class="modal-content res_video" >

                        <div class="modal-body" style={{ height: "500px", padding: "0", display: "flex", justifyContent: "center", alignItems: "center" }}>

                            <ReactPlayer url={url} width="100%" height="100%" playing controls></ReactPlayer>

                        </div>

                    </div>
                </div>
            </div>

            <div className=" d-lg-block d-none" data-Aos="zoom-in" style={{ width: "100%", marginBottom: "12%", padding: "0 10%" }}>
                <h1 style={{ marginBottom: "50px", fontWeight: "600" }}>Media Releases</h1>
                <div style={{ display: "flex", justifyContent: "space-between", columnGap: "8%" }}>

                    <div className="news" style={{ width: "30%" }}>
                        <div className="img-container-news" style={{ width: "100%", aspectRatio: "1/1" }} >
                            <a href="https://dainikbhaskarup.com/enbil-the-biggest-hyperlocal-changer-in-the-economy/" >
                                <img style={{ height: "100%", width: "100%", borderRadius: "36px" }} 
                                // src={require("../Assets/Images/news2.png")} 
                                src="https://d2f6yofx7yjl70.cloudfront.net/cloud/news2.png"
                                />
                                {/* <LazyLoadImage
                                    style={{ height: "100%", width: "100%", borderRadius: "36px" }}
                                    effect="blur"
                                    src={require("../Assets/Images/news2.png")} /> */}
                            </a>
                        </div>
                        <div className="textNews" style={{ padding: "2%", marginTop: "5px", textAlign: "left" }} >
                            <h4 >EnBill: The biggest hyperlocal changer in the economy</h4>
                        </div>


                    </div>
                    <div className="news" style={{ width: "30%" }}>
                        <div className="img-container-news" style={{ width: "100%", aspectRatio: "1/1" }} >
                            <a href="https://theasianchronicle.com/enbill-was-awarded-as-best-innovative-retail-fintech-at-india-startup-summit-and-startup-achievers-awards-2022/" >
                                <img style={{ height: "100%", width: "100%", borderRadius: "36px" }} 
                                // src={require("../Assets/Images/news1.jpeg")} 
                                src="https://d2f6yofx7yjl70.cloudfront.net/cloud/news1.png"
                                />
                                {/* <LazyLoadImage
                                    style={{ height: "100%", width: "100%", borderRadius: "36px" }}
                                    effect="blur"
                                    src={require("../Assets/Images/news1.jpeg")} /> */}
                            </a>
                        </div>
                        <div className="textNews" style={{ padding: "2%", marginTop: "5px", textAlign: "left" }} >
                            <h4>EnBill was awarded as Best Innovative Retail Fintech</h4>
                        </div>


                    </div>
                    <div className="news" style={{ width: "30%" }}>
                        <div className="img-container-news" style={{ width: "100%", aspectRatio: "1/1" }} >
                            <a href="https://www.business-standard.com/content/press-releases-ani/enbill-largest-hyper-local-economy-enabler-123010500700_1.html" >
                                <img style={{ height: "100%", width: "100%", borderRadius: "36px" }} 
                                // src={require("../Assets/Images/enbillLogoBG.png")} 
                                src="https://d2f6yofx7yjl70.cloudfront.net/cloud/enbillLogoBG.png"
                                />
                                {/* <LazyLoadImage
                                    style={{ height: "100%", width: "100%", borderRadius: "36px" }}
                                    effect="blur"
                                    src={require("../Assets/Images/enbillLogoBG.png")} /> */}
                            </a>

                        </div>
                        <div className="textNews" style={{ padding: "2%", marginTop: "5px", textAlign: "left" }} >
                            <h4>EnBill - Largest Hyper local Economy Enabler</h4>
                        </div>


                    </div>

                </div>
            </div>



            <div className="container d-lg-none d-block" style={{ width: "100%", marginBottom: "12%" }}>
                <h1 style={{ marginBottom: "50px", fontWeight: "600" }}>Media Releases</h1>
                <div style={{ display: "grid", gridRowGap: "48px", gridTemplate: "auto", gridAutoColumns: "1fr", gridColumnGap: "26px", gridTemplateColumns: "1fr", padding: "5%" }}>

                    <div className="news" style={{ height: "20vh", margin: "2%" }}>
                        <div className="img-container-news" style={{ height: "20vh" }} >
                            <a href="https://dainikbhaskarup.com/enbil-the-biggest-hyperlocal-changer-in-the-economy/" >
                                <img style={{ height: "100%", width: "70%", borderRadius: "20px" }} 
                                // src={require("../Assets/Images/news2.png")}
                                src="https://d2f6yofx7yjl70.cloudfront.net/cloud/news2.png"
                                 />
                                {/* <LazyLoadImage
                                    style={{ height: "100%", width: "70%", borderRadius: "20px" }} s
                                    effect="blur"
                                    src={require("../Assets/Images/news2.png")} /> */}
                            </a>
                        </div>
                        <div className="textNews" style={{ marginTop: "20px", marginBottom: "20px" }}  >
                            <h4 >EnBill: The biggest hyperlocal changer in the economy</h4>
                        </div>


                    </div>
                    <div className="news" style={{ height: "20vh", margin: "2%", marginTop: "50px" }}>
                        <div className="img-container-news" style={{ height: "20vh" }} >
                            <a href="https://theasianchronicle.com/enbill-was-awarded-as-best-innovative-retail-fintech-at-india-startup-summit-and-startup-achievers-awards-2022/" >
                                <img style={{ height: "100%", width: "70%", borderRadius: "20px" }} 
                                // src={require("../Assets/Images/news1.jpeg")} 
                                src="https://d2f6yofx7yjl70.cloudfront.net/cloud/news1.png"
                                />
                                {/* <LazyLoadImage
                                    style={{ height: "100%", width: "70%", borderRadius: "20px" }}
                                    effect="blur"
                                    src={require("../Assets/Images/news1.jpeg")} /> */}
                            </a>
                        </div>
                        <div className="textNews" style={{ marginTop: "20px", marginBottom: "20px" }}  >
                            <h4>EnBill was awarded as Best Innovative Retail Fintech</h4>
                        </div>


                    </div>
                    <div className="news" style={{ height: "20vh", margin: "2%", marginTop: "50px", marginBottom: "30px" }}>
                        <div className="img-container-news" style={{ height: "20vh" }} >
                            <a href="https://www.business-standard.com/content/press-releases-ani/enbill-largest-hyper-local-economy-enabler-123010500700_1.html" >
                                <img style={{ height: "100%", width: "50%", borderRadius: "20px" }} 
                                // src={require("../Assets/Images/enbillLogoBG.png")} 
                                src="https://d2f6yofx7yjl70.cloudfront.net/cloud/enbillLogoBG.png"
                                />
                                {/* <LazyLoadImage
                                    style={{ height: "100%", width: "50%", borderRadius: "20px" }}
                                    effect="blur"
                                    src={require("../Assets/Images/enbillLogoBG.png")} /> */}
                            </a>

                        </div>
                        <div className="textNews" style={{ marginTop: "20px", marginBottom: "20px" }}  >
                            <h4>EnBill - Largest Hyper local Economy Enabler</h4>
                        </div>


                    </div>

                </div>
            </div>



            <div className="sm-mt-5" style={{ marginTop: "100px", marginBottom: "50px" }}>
                <div>
                    <h1 style={{ fontWeight: "600" }}>Connected Merchants</h1>
                </div>
                <Marquee speed="60" style={{ marginBottom: "50px", marginLeft: "0", marginRight: "0", padding: "0", borderRadius: "20px" }}  >
                    <div>
                        <img className="img-fluid shopLogo" style={{ height: "150px", width: "180px", margin: "15px" }} 
                        // src={require("../Assets/Images/shopLogo1.png")} 
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/shopLogo1.png"
                        />
                    </div>
                    <div>
                        <img className="img-fluid shopLogo" style={{ height: "150px", width: "180px", margin: "15px" }} 
                        // src={require("../Assets/Images/shopLogo2.png")}
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/shopLogo2.png"

                         />
                    </div>
                    <div>
                        <img className="img-fluid shopLogo" style={{ height: "150px", width: "180px", margin: "15px" }} 
                        // src={require("../Assets/Images/shopLogo3.png")}
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/shopLogo3.png"
                         />
                    </div>
                    <div>
                        <img className="img-fluid shopLogo" style={{ height: "150px", width: "180px", margin: "15px" }} 
                        // src={require("../Assets/Images/shopLogo4.png")} />
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/shopLogo4.png" />
                    </div>
                    <div>
                        <img className="img-fluid shopLogo" style={{ height: "150px", width: "180px", margin: "15px" }} 
                        // src={require("../Assets/Images/shopLogo5.png")} 
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/shopLogo5.png"
                        />
                    </div>
                    <div>
                        <img className="img-fluid shopLogo" style={{ height: "150px", width: "180px", margin: "15px" }}
                        //  src={require("../Assets/Images/shopLogo1.png")} 
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/shopLogo1.png"
                         />
                    </div>
                    <div>
                        <img className="img-fluid shopLogo" style={{ height: "150px", width: "180px", margin: "15px" }} 
                        // src={require("../Assets/Images/shopLogo2.png")}
                        src= "https://d2f6yofx7yjl70.cloudfront.net/cloud/shopLogo2.png"
                        />
                    </div>
                    <div>
                        <img className="img-fluid shopLogo" style={{ height: "150px", width: "180px", margin: "15px" }} 
                        // src={require("../Assets/Images/shopLogo3.png")}
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/shopLogo3.png"
                         />
                    </div>
                    <div>
                        <img className="img-fluid shopLogo" style={{ height: "150px", width: "180px", margin: "15px" }} 
                        // src={require("../Assets/Images/shopLogo4.png")} 
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/shopLogo4.png"
                        />
                    </div>
                    <div>
                        <img className="img-fluid shopLogo" style={{ height: "150px", width: "180px", margin: "15px" }} 
                        // src={require("../Assets/Images/shopLogo5.png")}
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/shopLogo5.png"
                         />
                    </div>
                </Marquee>
            </div>

           



            <FooterLandingPage></FooterLandingPage>

        </div>
    )

}

export default EnbillLandingPage;

