import { useState, useRef, useEffect, useCallback } from "react";
import React from 'react'
import { create } from '@lottiefiles/lottie-interactivity';
import Lottie from "lottie-web";
import Aos from "aos";
import "aos/dist/aos.css";
import "./EnbillMerchant.css";
import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useIsVisible } from 'react-is-visible'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
// import 'ImagesAnimation4.json'

const EnbillUserPage = () => {


    const [animateScroll, setAnimateScroll] = useState(40);
    const [animateScrollMB, setAnimateScrollMB] = useState(35);
    const [animateScroll2, setAnimateScroll2] = useState(30);
    const [animateScroll3, setAnimateScroll3] = useState(25);
    const [animateScrollMB3, setAnimateScrollMB3] = useState(15);
    const [animateScroll4, setAnimateScroll4] = useState(16);
    const [zoomVid, setZoomVid] = useState(false)
    const [loadBorders, setLoadBorders] = useState(false);
    const [showHeader, setShowHeader] = useState("none")

    setTimeout(() => {
        setLoadBorders(true);
    }, 1000)

    setTimeout(() => {
        setShowHeader("block");
    }, 1500)


    const handleScroll = () => {
        const scrollPosition = window.scrollY; // => scroll position
        console.log(scrollPosition);


        if (scrollPosition > 100 && scrollPosition < 200) {
            setAnimateScroll(30)
        } else if (scrollPosition < 100) {
            setAnimateScroll(40)
        }
        if (scrollPosition > 200) {
            setAnimateScroll(20)
        } else if (scrollPosition < 200 && scrollPosition > 100) {
            setAnimateScroll(30)
        }
        if (scrollPosition > 300) {
            setAnimateScroll(10)
        }

        if (scrollPosition > 100 && scrollPosition < 200) {
            setAnimateScrollMB(30)
        }
        else if (scrollPosition < 100) {
            setAnimateScrollMB(35)
        }
        if (scrollPosition > 200) {
            setAnimateScrollMB(20)
        } else if (scrollPosition < 200 && scrollPosition > 100) {
            setAnimateScrollMB(30)
        }
        if (scrollPosition > 300) {
            setAnimateScrollMB(10)
        }


        if (scrollPosition > 100 && scrollPosition < 200) {
            setAnimateScroll2(20)
        } else if (scrollPosition < 100) {
            setAnimateScroll2(30)
        }
        if (scrollPosition > 200) {
            setAnimateScroll2(10)
        } else if (scrollPosition < 200 && scrollPosition > 100) {
            setAnimateScroll2(20)
        }
        if (scrollPosition > 300) {
            setAnimateScroll2(5)
        }



        if (scrollPosition > 100 && scrollPosition < 200) {
            setAnimateScroll3(30)
        } else if (scrollPosition < 100) {
            setAnimateScroll3(25)
        }
        if (scrollPosition > 200) {
            setAnimateScroll3(35)
        } else if (scrollPosition < 200 && scrollPosition > 100) {
            setAnimateScroll3(30)
        }
        if (scrollPosition > 300) {
            setAnimateScroll3(40)
        }


        if (scrollPosition > 100 && scrollPosition < 200) {
            setAnimateScrollMB3(20)
        } else if (scrollPosition < 100) {
            setAnimateScrollMB3(15)
        }
        if (scrollPosition > 200) {
            setAnimateScrollMB3(25)
        } else if (scrollPosition < 200 && scrollPosition > 100) {
            setAnimateScrollMB3(20)
        }
        if (scrollPosition > 300) {
            setAnimateScrollMB3(30)
        }




        if (scrollPosition > 100 && scrollPosition < 200) {
            setAnimateScroll4(20)
        } else if (scrollPosition < 100) {
            setAnimateScroll4(15)
        }
        if (scrollPosition > 200) {
            setAnimateScroll4(25)
        } else if (scrollPosition < 200 && scrollPosition > 100) {
            setAnimateScroll4(20)
        }
        if (scrollPosition > 300) {
            setAnimateScroll4(30)
        }



    };



    useEffect(() => {
        handleScroll();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);



    const nodeRef = useRef()
    const isVisible = useIsVisible(nodeRef)
    const container5 = useRef(null);


    useEffect(() => {
        Lottie.loadAnimation({
            container: container5.current,
            renderer: "svg",
            loop: false,
            autoplay: true,
            // animationData: require("../Assets/Images/UserBooked.json")
            // path:"https://enbfile.s3.ap-south-1.amazonaws.com/enbill_in/json/UserBooked.json"
            path:"https://d2f6yofx7yjl70.cloudfront.net/cloud/UserBooked.json"
        });
        return () => {
            Lottie.destroy();
        };
    }, [isVisible])

    const nodeReff = useRef()


    const [chromeBrowser, setChromeBrowser] = useState(false);
    useEffect(() => {
        const { detect } = require('detect-browser');
        const browser = detect();


        // handle the case where we don't detect the browser
        switch (browser && browser.name) {
            case 'chrome':
                setChromeBrowser(true);
            case 'firefox':
                console.log('supported');
                break;

            case 'edge':
                console.log('kinda ok');
                break;

            default:
                console.log('not supported');
        }

    }, [])


    const lottiee = React.useRef(null);

    React.useEffect(() => {
        lottiee.current.addEventListener("load", function (e) {
            create({
                mode: "scroll",
                player: "#firstLottie",
                container: '#container21',
                actions: [
                    {
                        visibility: [0, 1],
                        type: "seek",
                        frames: [0, 281]

                    }
                ]
            });
        });
    }, [lottiee]);


    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, [])

    const lottieee = React.useRef(null);

    React.useEffect(() => {
        lottiee.current.addEventListener("load", function (e) {
            create({
                mode: "scroll",
                player: "#lastLottie",
                container: '#containerLast',
                actions: [
                    {
                        visibility: [0, 1],
                        type: "seek",
                        frames: [0, 181]

                    }
                ]
            });
        });
    }, [lottieee]);
    useEffect(() => {
        console.log("hhhjhiug", zoomVid)
    }, [zoomVid])

    const { pathname } = useLocation();

    const [y, setY] = useState(window.scrollY);

    const [headerTop, setHeaderTop] = useState(true);
    const [drop, setDrop] = useState(false);
    const [showNextHeader, setShowNextHeader] = useState(false)


    const handleNavigation = useCallback(
        e => {

            const window = e.currentTarget;
            if (y > window.scrollY) {
                setHeaderTop(true)

            } else if (y < window.scrollY) {
                setHeaderTop(false)

                setDrop(false)
            }
            if(y > 20) {
                setShowNextHeader(true)
            } else {
                setShowNextHeader (false)
            }

            setY(window.scrollY);
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    return (
        <>
          

            <nav id='heading'  className={`navbar navbar-expand-lg navbar-dark px-lg-5 px-2 row ${headerTop ? "headertop" : "headerbottom"}`}
                style={{  display: showNextHeader ? "flex" : "none", transition: "all 0.5s", zIndex: "9999999", width: "100%", margin: "0", position: "fixed", backgroundColor: "#EE7A37",  }}>
                <div class="container-fluid">
                    <a class="navbar-brand px-3" href="/" style={{ backgroundColor: "white", borderRadius: "30px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "30px", padding: "2px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }} >
                        <img src={require("../Assets/Images/logoenbil.png")} />
                        <span style={{ color: "#EE7A37", fontWeight: "500" }}>EnBill</span>
                    </a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0 " style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                            <li className={`nav-item px-3  ${pathname === '/EnbillMerchantPage' || pathname === '/EnbillUserPage' ? "activeList" : ""}`} style={{

                            }}>
                                <div class="dropdown show">
                                    <a href="#" data-toggle="dropdown" style={{ textDecoration: "none" }}>
                                        <li className="nav-item px-3 " style={{
                                        }}>
                                            <a style={{}} className="nav-link" href="/EnbillCareerPage">SERVICES</a>
                                        </li>
                                    </a>


                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", marginTop: "20px", zIndex: "9999", paddingTop: "0", paddingBottom: "0", }}>
                                        <a className={`userDropDown headerlist  ${pathname === '/EnbillMerchantPage' ? "activeDropMerchant" : ""}`} href="/EnbillMerchantPage" style={{ textAlign: "center" }}>Merchant</a>
                                        <a className={`userDropDown headerlist  ${pathname === '/EnbillUserPage' ? "activeDropUser" : ""}`} href="/EnbillUserPage" style={{ textAlign: "center" }}>User</a>
                                    </div>
                                </div>
                            </li>

                            <li className={`nav-item px-3  ${pathname === '/ContactUs' ? "activeList" : ""}`} style={{

                            }}>
                                <a style={{}} className={(pathname === '/ContactUs') ? 'active' : 'nav-link'} href="/ContactUs">CONTACT US</a>
                            </li>
                            <li className={`nav-item px-3 d-block d-lg-none  ${pathname === '/AboutUs' ? "activeList" : ""}`} style={{

                            }}>
                                <div class="dropdown show">
                                    <a href="#" data-toggle="dropdown" style={{ textDecoration: "none" }}>
                                        <li style={{

                                        }}>
                                            <a style={{ textDecoration: "none", fontWeight: "600", fontSize: "20px" }} className="nav-link" href="/EnbillCareerPage">SIGN IN</a>
                                        </li>
                                    </a>


                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", marginTop: "20px", zIndex: "9999", paddingTop: "0", paddingBottom: "0", }}>
                                        <a class="dropdown-item headerlist " target="_blank" href="https://merchant.enbill.in/">MERCHANT SIGN IN</a>
                                        <a class="dropdown-item headerlist " target="_blank" href="http://ios.enbill.co/">CUSTOMER SIGN IN</a>

                                    </div>
                                </div>
                            </li>
                            <li class="nav-item px-3 d-none d-lg-block" style={{
                                position: "absolute", right: "5%"
                            }}>
                                <div class="dropdown show">
                                    <a href="#" data-toggle="dropdown" style={{ textDecoration: "none" }}>
                                        <li style={{

                                        }}>
                                            <a style={{ textDecoration: "none", fontWeight: "600", fontSize: "20px" }} className="nav-link" href="/EnbillCareerPage">SIGN IN</a>
                                        </li>
                                    </a>


                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", marginTop: "20px", zIndex: "9999", paddingTop: "0", paddingBottom: "0" }}>
                                        <a class="dropdown-item headerlist " target="_blank" href="https://merchant.enbill.in/">MERCHANT SIGN IN</a>
                                        <a class="dropdown-item headerlist " target="_blank" href="http://ios.enbill.co/">CUSTOMER SIGN IN</a>
                                    </div>
                                </div>


                            </li>
                        </ul>

                    </div>
                </div>
            </nav>

            <nav id='heading' className={`navbar navbar-expand-lg navbar-dark px-lg-5 px-2 row `}
                style={{ display: showNextHeader ? "none" : "flex", transition: "all 0.5s", zIndex: "9999999", width: "100%", margin: "0", position: "fixed", backgroundColor: "#EE7A37", }}>
                <div class="container-fluid">
                    <a class="navbar-brand px-3" href="/" style={{ backgroundColor: "white", borderRadius: "30px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "30px", padding: "2px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }} >
                        <img src={require("../Assets/Images/logoenbil.png")} />
                        <span style={{ color: "#EE7A37", fontWeight: "500" }}>EnBill</span>
                    </a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0 " style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                            <li className={`nav-item px-3  ${pathname === '/EnbillMerchantPage' || pathname === '/EnbillUserPage' ? "activeList" : ""}`} style={{

                            }}>
                                <div class="dropdown show">
                                    <a href="#" data-toggle="dropdown" style={{ textDecoration: "none" }}>
                                        <li className="nav-item px-3 " style={{
                                        }}>
                                            <a style={{}} className="nav-link" href="/EnbillCareerPage">SERVICES</a>
                                        </li>
                                    </a>


                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", marginTop: "20px", zIndex: "9999", paddingTop: "0", paddingBottom: "0", }}>
                                        <a className={`userDropDown headerlist  ${pathname === '/EnbillMerchantPage' ? "activeDropMerchant" : ""}`} href="/EnbillMerchantPage" style={{ textAlign: "center" }}>Merchant</a>
                                        <a className={`userDropDown headerlist  ${pathname === '/EnbillUserPage' ? "activeDropUser" : ""}`} href="/EnbillUserPage" style={{ textAlign: "center" }}>User</a>
                                    </div>
                                </div>
                            </li>
                            <li className={`nav-item px-3  ${pathname === '/ContactUs' ? "activeList" : ""}`} style={{

                            }}>
                                <a style={{}} className={(pathname === '/ContactUs') ? 'active' : 'nav-link'} href="/ContactUs">CONTACT US</a>
                            </li>
                            <li className={`nav-item px-3 d-block d-lg-none  ${pathname === '/AboutUs' ? "activeList" : ""}`} style={{

                            }}>
                                <div class="dropdown show">
                                    <a href="#" data-toggle="dropdown" style={{ textDecoration: "none" }}>
                                        <li style={{

                                        }}>
                                            <a style={{ textDecoration: "none", fontWeight: "600", fontSize: "20px" }} className="nav-link" href="/EnbillCareerPage">SIGN IN</a>
                                        </li>
                                    </a>


                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", marginTop: "20px", zIndex: "9999", paddingTop: "0", paddingBottom: "0", }}>
                                        <a class="userDropDown headerlist " target="_blank" href="https://merchant.enbill.in/">MERCHANT SIGN IN</a>
                                        <a class="userDropDown headerlist " target="_blank" href="http://ios.enbill.co/">CUSTOMER SIGN IN</a>

                                    </div>
                                </div>
                            </li>
                            <li class="nav-item px-3 d-none d-lg-block" style={{
                                position: "absolute", right: "5%"
                            }}>
                                <div class="dropdown show">
                                    <a href="#" data-toggle="dropdown" style={{ textDecoration: "none" }}>
                                        <li style={{

                                        }}>
                                            <a style={{ textDecoration: "none", fontWeight: "600", fontSize: "20px" }} className="nav-link" href="/EnbillCareerPage">SIGN IN</a>
                                        </li>
                                    </a>


                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", marginTop: "20px", zIndex: "9999", paddingTop: "0", paddingBottom: "0" }}>
                                        <a class="userDropDown headerlist " target="_blank" href="https://merchant.enbill.in/">MERCHANT SIGN IN</a>
                                        <a class="userDropDown headerlist " target="_blank" href="http://ios.enbill.co/">CUSTOMER SIGN IN</a>
                                    </div>
                                </div>


                            </li>
                        </ul>

                    </div>
                </div>
            </nav>
            <div className="row d-lg-flex d-none userFirstbox" style={{ width: "100%", backgroundColor: "white", height: "90vh", position: "relative", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }} >
                <img className={`${loadBorders ? "showBorderImg" : ""}`} style={{ borderRadius: "10px", position: "absolute", top: `${animateScroll}%`, padding: "0", margin: "0" }} id="testing" 
                //src={require("../Assets/Images/UserDashWeb.png")}
                src="https://d2f6yofx7yjl70.cloudfront.net/cloud/UserDashWeb.png"
                 />
                <img style={{ borderRadius: "10px", position: "absolute", height: "56vh", transition: "all 1s", zIndex: "999", width: "18vw", top: `${animateScroll3}%`, left: "18%", padding: "0", margin: "0" }} 
                // src={require("../Assets/Images/UserDashMob.png")}
                src="https://d2f6yofx7yjl70.cloudfront.net/cloud/UserDashMob.png"
                 />
                {chromeBrowser && chromeBrowser ? <>
                    <div style={{ zIndex: "99999", color: "white", display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center", flexDirection: "column" }}>
                        <p style={{
                            font: "normal normal 600 4.5vw/6vw Source Serif Pro", width: "25vw", fontSize: "4.5vw", marginLeft: "10px"
                        }} >Shop <br></br> Online bhi Offline bhi</p>
                        {/* <p style={{
                            font: "normal normal 600 4.5vw/6vw Source Serif Pro", width: "25vw", fontSize: "4.5vw", marginLeft: "10px"
                        }} >Offline bhi</p> */}
                    </div>
                </> :
                    <>
                        <div style={{ zIndex: "99999", color: "white", display: "flex", justifyContent: "center", textAlign: "center", alignItems: "center", flexDirection: "column" }}>

                            <p style={{
                                fontFamily: "Source Serif Pro", width: "20vw", fontSize: "3vw", fontWeight: "600"
                            }} >Shop <br></br>Online bhi Offline bhi</p>
                        </div>
                    </>}
                <img className={`${loadBorders ? "showBorderImg" : ""}`} style={{ borderRadius: "10px", height: "52vh", width: "15.625vw", transition: "all 1s", zIndex: "999", padding: "0", margin: "0", position: "absolute", right: "17%", top: `${animateScroll2}%` }} 
                // src={require("../Assets/Images/UserBookingMob2.png")}
                src="https://d2f6yofx7yjl70.cloudfront.net/cloud/UserBookingMob2.png"
                 />
                <img style={{ borderRadius: "10px", top: `${animateScroll4}%`, padding: "0", margin: "0", height: "35vh", width: "35vw", position: "absolute", right: "1%", transition: "all 1s" }}
                //  src={require("../Assets/Images/UserBookingWeb.png")}
                src="https://d2f6yofx7yjl70.cloudfront.net/cloud/UserBookingWeb.png"
                  />
            </div>
            <div className="d-flex d-lg-none userFirstbox" style={{ width: "100%", borderBottomLeftRadius: "30px", WebkitBorderBottomRightRadius: "30px", backgroundColor: "white", height: "90vh", position: "relative", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }} >
                <img className="dashWebImg" style={{ position: "absolute", top: `${animateScrollMB}%`, padding: "0", margin: "0", left: "30%" }} 
                //src={require("../Assets/Images/UserDashWeb.png")}
                src="https://d2f6yofx7yjl70.cloudfront.net/cloud/UserDashWeb.png"
                 />
                <img className="dashMobImg" style={{ position: "absolute", height: "50vw", width: "25vw", transition: "all 1s", zIndex: "999", top: `${animateScrollMB3}%`, left: "48%", padding: "0", margin: "0" }} 
                // src={require("../Assets/Images/UserDashMob.png")}
                src="https://d2f6yofx7yjl70.cloudfront.net/cloud/UserDashMob.png"
                 />
                <div style={{ zIndex: "99999", marginLeft: "2vw", marginTop: "80%" }}>
                    <p style={{ color: "white", fontWeight: "700", fontSize: "6.5vw" }}>Shop <br></br>Online bhi Offline bhi</p>
                    {/* <p style={{ color: "white", fontSize: "7.5vw", fontWeight: "400", }} >One stop solution for your dukaan needs</p> */}
                </div>
            </div>

            <div className="row d-lg-block d-none " style={{ width: "100%" }}>
                <div className="section col" id="container21" style={{ position: "relative" }}  >

                    <div className={` firstStrech ${loadBorders ? "setOrangeBG" : ""}`} style={{ position: "absolute", left: "0", rightL: "0", top: "0", bottom: "auto", width: "100%", height: "25.5vw", borderBottomLeftRadius: "50px", borderBottomRightRadius: "50px", }} ></div>
                    <div className="secondMainContainer" style={{ width: "90%", maxWidth: "130rem", marginLeft: "auto", marginRight: "auto" }} >
                        <img className={`img-fluid ${loadBorders ? "showBorder" : ""}`} style={{ borderRadius: "35px", position: "relative", width: "75%", height: "559px" }} 
                        // src={require("../Assets/Images/UserSecondBG2.png")} 
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/UserSecondBG2.png"
                        />
                        <div className="animationWrapper" style={{ position: "relative", height: "600vh", display: "flex", maxWidth: "100rem", marginTop: "-29.5rem", marginRight: "auto", marginLeft: "auto", paddingRight: "6rem", justifyContent: "space-between", alignItems: "flex-start" }} >
                            <div className="animationLeft" style={{ boxSizing: "border-box", width: '67.5%' }}>
                                <div className="animationSpaceMaker" style={{ height: "30rem" }} >

                                </div>
                                <div className="textAnimationWrapper" style={{ position: "relative", width: "44.7rem", height: "520vh", textAlign: "center" }} >
                                    <div style={{ position: "sticky", top: "18rem" }} >
                                        <p style={{ fontWeight: "500", fontSize: "5.5vw" }}>Order kabhi bhi <br></br> kahi se bhi</p>
                                    </div>
                                </div>
                            </div>
                            <div className="animationRight" style={{ position: "sticky", top: "10rem", maxWidth: "40rem", width: "80%" }} >
                                <div style={{ height: "560px", width: "270px" }}>
                                    <lottie-player
                                        ref={lottiee}
                                        id="firstLottie"
                                        // src="https://enbfile.s3.ap-south-1.amazonaws.com/enbill_in/json/ImagesAnimation4.json"
                                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/ImagesAnimation4.json"
                                        // src="images/test.json"
                                        // src={require("../../public/test.json")}
                                        // src="ImagesAnimation4.json"
                                    >
                                    </lottie-player>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-block d-lg-none" style={{ display: "none" }}>
                <div className="col" style={{ display: "flex", justifyContent: "center", alignItems: "Center", flexDirection: "column" }}>

                    <div style={{ padding: "10%", width: "100%" }}>
                        <p style={{ fontWeight: "600", fontSize: "40px" }}>Order kabhi bhi <br></br> kahi se bhi</p>

                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "Center", flexDirection: "column" }} ref={nodeRef} >
                        <div style={{ height: "80vw", width: "80vw" }} ref={container5}>

                        </div>
                    </div>

                </div>
            </div>
            <div className="row p-5 MerchantFeatureRow d-sm-flex d-lg-none" style={{ padding: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <p style={{ fontWeight: "500", fontSize: "60px" }}>Features of EnBill User</p>
                <p style={{ color: "#787878", fontSize: "24px", fontWeight: "400" }}>EnBill will provide you with an unique & amazing features</p>
                <div data-Aos="zoom-in" className="col-2 m-4 UserFeature" style={{ height: "233px", width: "233px", color: "#2b2b2b", padding: "1%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "20px", paddingBottom: "20px" }}>
                    <div style={{ margin: "10px" }}>
                        <img style={{ height: "100px", width: "140px" }} 
                        // src={require("../Assets/Images/userFeature1Ne.png")}
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/userFeature1Ne.png"
                         />
                    </div>
                    <div>
                        <p style={{ fontWeight: "500", fontSize: "20px", whiteSpace: "nowrap" }} >Delivery or Pickup</p>

                    </div>


                </div>
                <div data-Aos="zoom-in" className="col-2 m-4 UserFeature" style={{ height: "233px", width: "233px", color: "#2b2b2b", padding: "1%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "20px", paddingBottom: "20px" }}>
                    <div style={{ margin: "10px" }}>
                        <img style={{ height: "100px", width: "100px" }} 
                        // src={require("../Assets/Images/usersFeatLocation.png")} 
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/usersFeatLocation.png"
                        />
                    </div>
                    <div>
                        <p style={{ fontWeight: "500", fontSize: "20px" }} >Live location</p>

                    </div>


                </div>
                <div data-Aos="zoom-in" className="col-2 m-4 UserFeature" style={{ height: "233px", width: "233px", color: "#2b2b2b", padding: "1%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "20px", paddingBottom: "20px" }}>
                    <div style={{ margin: "10px" }}>
                        <img style={{ height: "100px", width: "150px" }} 
                        // src={require("../Assets/Images/userFeature3.png")} 
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/userFeature3.png"
                        />
                    </div>
                    <div>
                        <p style={{ fontWeight: "500", fontSize: "20px" }} >Digital catalouge</p>
                    </div>
                </div>
                <div data-Aos="zoom-in" className="col-2 m-4 UserFeature" style={{ height: "233px", width: "233px", color: "#2b2b2b", padding: "1%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "20px", paddingBottom: "20px" }}>
                    <div style={{ margin: "10px" }}>
                        <img style={{ height: "130px", }} 
                        // src={require("../Assets/Images/usersFeatBill.png")} 
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/usersFeatBill.png"
                        />
                    </div>
                    <div>
                        <p style={{ fontWeight: "500", fontSize: "20px" }} >Bills at one place</p>
                    </div>
                </div>
                <div data-Aos="zoom-in" className="col-2 m-4 UserFeature" style={{ height: "233px", width: "233px", color: "#2b2b2b", padding: "1%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "20px", paddingBottom: "20px" }}>
                    <div style={{ margin: "10px" }}>
                        <img style={{ height: "100px", width: "100px" }} 
                        // src={require("../Assets/Images/userFeature5.png")} 
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/userFeature5.png"
                        />
                    </div>
                    <div>
                        <p style={{ fontWeight: "500", fontSize: "20px" }} >Connect with your near by shopkeepers</p>
                    </div>
                </div>
                <div data-Aos="zoom-in" className="col-2 m-4 UserFeature" style={{ height: "233px", width: "233px", color: "#2b2b2b", padding: "1%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "20px", paddingBottom: "20px" }}>
                    <div style={{ margin: "10px" }}>
                        <img style={{ height: "100px", width: "140px" }} 
                        // src={require("../Assets/Images/userFeature6.png")} 
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/userFeature6.png"
                        />
                    </div>
                    <div>
                        <p style={{ fontWeight: "500", fontSize: "20px" }} >Get direct updates from dukaandar</p>

                    </div>


                </div>
                <div data-Aos="zoom-in" className="col-2 m-4 UserFeature" style={{ height: "233px", width: "233px", color: "#2b2b2b", padding: "1%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "20px", paddingBottom: "20px" }}>
                    <div style={{ margin: "10px" }}>
                        <img style={{ height: "130px", width: "60px" }} 
                        // src={require("../Assets/Images/userFeature7.png")}
                        src="https://d2f6yofx7yjl70.cloudfront.net/cloud/userFeature7.png"
                         />

                    </div>
                    <div>
                        <p style={{ fontWeight: "500", fontSize: "20px" }} >Booking status</p>

                    </div>


                </div>
                <div data-Aos="zoom-in" className="col-2 m-4 UserFeature" style={{ height: "233px", width: "233px", color: "#2b2b2b", padding: "1%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "20px", paddingBottom: "20px" }}>
                    <div style={{ margin: "10px" }}>
                        <img style={{ height: "130px", width: "60px" }} 
                        // src={require("../Assets/Images/usersFeatUdhaar.png")}
                        src= "https://d2f6yofx7yjl70.cloudfront.net/cloud/usersFeatUdhaar.png"
                         />
                    </div>
                    <div>

                        <p style={{ fontWeight: "500", fontSize: "20px" }} >Udhaar</p>

                    </div>

                </div>


            </div>

            {/* web */}

            <div className=" d-lg-block d-none " style={{ marginTop: "5%", width: "100%", marginBottom: "12%", padding: "0 10%" }}>
                <p style={{ fontWeight: "500", fontSize: "60px" }}>Features of EnBill User</p>
                <p style={{ color: "#787878", fontSize: "24px", fontWeight: "400" }}>EnBill will provide you with an unique & amazing features</p>
                <div style={{ display: "grid", gridRowGap: "48px", gridTemplate: "auto", gridAutoColumns: "1fr", gridColumnGap: "26px", gridTemplateColumns: "1fr 1fr 1fr 1fr", marginTop: "2%" }}>

                    <div className="UserFeature" style={{ height: "233px", color: "#2b2b2b", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "20px", }}>
                        <div style={{ margin: "10px", width: "150px", minHeight: "130px" }}>
                            <img style={{ height: "110px" }}
                            //  src={require("../Assets/Images/userFeature1Ne.png")}
                            src="https://d2f6yofx7yjl70.cloudfront.net/cloud/userFeature1Ne.png"
                              />
                        </div>
                        <div>
                            <p style={{ fontWeight: "500", fontSize: "1.5vw", whiteSpace: "nowrap" }} >Delivery or Pickup</p>

                        </div>


                    </div>
                    <div className="UserFeature" style={{ height: "233px", color: "#2b2b2b", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "20px", }}>
                        <div style={{ margin: "10px", width: "150px", minHeight: "130px" }}>
                            <img style={{ height: "140px", }} 
                            // src={require("../Assets/Images/usersFeatLocation.png")}
                            src="https://d2f6yofx7yjl70.cloudfront.net/cloud/usersFeatLocation.png" 
                            />
                        </div>
                        <div>
                            <p style={{ fontWeight: "500", fontSize: "1.5vw", }} >Live location</p>

                        </div>


                    </div>
                    <div className="UserFeature" style={{ height: "233px", color: "#2b2b2b", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "20px", }}>
                        <div style={{ margin: "10px", width: "150px", minHeight: "130px" }}>
                            <img style={{ height: "110px", }}
                            //  src={require("../Assets/Images/userFeature3.png")}
                            src="https://d2f6yofx7yjl70.cloudfront.net/cloud/userFeature3.png"
                              />
                        </div>
                        <div>
                            <p style={{ fontWeight: "500", fontSize: "1.5vw", }} >Digital catalouge</p>

                        </div>


                    </div>
                    <div className="UserFeature" style={{ height: "233px", color: "#2b2b2b", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "20px", }}>
                        <div style={{ margin: "10px", width: "150px", minHeight: "130px" }}>
                            <img style={{ height: "110px", }}
                            //  src={require("../Assets/Images/usersFeatBill.png")}
                            src="https://d2f6yofx7yjl70.cloudfront.net/cloud/usersFeatBill.png"
                              />
                        </div>
                        <div>
                            <p style={{ fontWeight: "500", fontSize: "1.5vw", }} >Bills at one place</p>

                        </div>


                    </div>
                    <div className="UserFeature" style={{ height: "233px", color: "#2b2b2b", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "20px", }}>
                        <div style={{ margin: "10px", width: "150px", minHeight: "130px" }}>
                            <img style={{ height: "110px", }}
                            //  src={require("../Assets/Images/userFeature5.png")} 
                            src="https://d2f6yofx7yjl70.cloudfront.net/cloud/userFeature5.png"
                             />
                        </div>
                        <div>
                            <p style={{ fontWeight: "500", fontSize: "1.5vw", }} >Connect with your near by shopkeepers</p>

                        </div>


                    </div>
                    <div className="UserFeature" style={{ height: "233px", color: "#2b2b2b", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "20px", }}>
                        <div style={{ margin: "10px", width: "150px", minHeight: "130px" }}>
                            <img style={{ height: "110px", }}
                            //  src={require("../Assets/Images/userFeature6.png")}
                            src="https://d2f6yofx7yjl70.cloudfront.net/cloud/userFeature6.png"
                              />
                        </div>
                        <div>
                            <p style={{ fontWeight: "500", fontSize: "1.5vw", }} >Get direct updates from dukaandar</p>

                        </div>


                    </div>
                    <div className="UserFeature" style={{ height: "233px", color: "#2b2b2b", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "20px", }}>
                        <div style={{ margin: "10px", width: "150px", minHeight: "130px" }}>
                            <img style={{ height: "110px", }} 
                            // src={require("../Assets/Images/userFeature7.png")} 
                            src="https://d2f6yofx7yjl70.cloudfront.net/cloud/userFeature7.png"
                            />
                        </div>
                        <div>
                            <p style={{ fontWeight: "500", fontSize: "1.5vw", }} >Booking status</p>

                        </div>


                    </div>
                    <div className="UserFeature" style={{ height: "233px", color: "#2b2b2b", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "20px", }}>
                        <div style={{ margin: "10px", width: "150px", minHeight: "130px" }}>
                            <img style={{ height: "110px", }} 
                            // src={require("../Assets/Images/usersFeatUdhaar.png")}
                            src= "https://d2f6yofx7yjl70.cloudfront.net/cloud/usersFeatUdhaar.png"
                             />
                        </div>
                        <div>
                            <p style={{ fontWeight: "500", fontSize: "1.5vw", }} >Udhaar</p>

                        </div>


                    </div>
                </div>
            </div>
            <div data-Aos="zoom-in" data-aos-delay="400" onClick={() => { setZoomVid(true) }} className={` ${zoomVid ? " d-lg-none d-none lastMerchnat" : "d-lg-block d-none lastMerchnat"}`} data-toggle="modal" data-target=".bd-example-modal-lg" style={{ zIndex: "9999", right: "20px", position: "fixed", top: "80%", padding: "0", margin: "0", boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px", height: "13vh", width: "15vw" }} >
                <div className="videoMerchantFixed" style={{ padding: "0", margin: "0" }}  >
                    <div style={{ padding: "0", margin: "0" }} >
                        <video controls muted loop autoPlay width="100%" height="100%">
                            <source src="/Videos/userLast.mp4" type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>



            <div style={{ zIndex: "999999" }} class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog modal-lg modal-dialog-centered"  >
                    <div class="modal-content" style={{ position: "relative" }}>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ position: "absolute", top: "2%", zIndex: "9999999", background: "none", border: "none", right: "10px" }}>
                            <CloseOutlinedIcon fontSize="large" onClick={() => { setZoomVid(false) }} ></CloseOutlinedIcon>
                        </button>
                        <div style={{ padding: "0", margin: "0" }} >
                            <video controls muted loop autoPlay width="100%" height="100%">
                                <source src="/Videos/userLast.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row" style={{ padding: "1%", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", width: "100%", marginTop: "20px" }}>

                <div className="col">
                    <p style={{ fontWeight: "600", fontSize: "16px" }}>Quick Links</p>


                    <a href="/EnbillCareerPage" style={{ display: "block", textDecoration: "none", fontSize: "16px", color: "#545D63", marginTop: "5px" }}>Career</a>
                    <a href="/ContactUs" style={{ display: "block", textDecoration: "none", fontSize: "16px", color: "#545D63", marginTop: "5px" }}>Contact us</a>


                </div>

                <div className="col">
                    <p style={{ fontWeight: "600", fontSize: "16px" }}>Download The Apps</p>

                    <a href="https://play.google.com/store/search?q=enbill&c=apps" target="_blank" style={{ display: "block", textDecoration: "none", fontSize: "16px", color: "#545D63", marginTop: "5px" }}>Playstore</a>
                    <a href="https://apps.apple.com/in/app/enbill/id1659215655" target="_blank" style={{ display: "block", textDecoration: "none", fontSize: "16px", color: "#545D63", marginTop: "5px" }}>Appstore</a>


                </div>

                <div className="col">
                    <p style={{ fontWeight: "600", fontSize: "16px" }}>Registered Office</p>
                    <p style={{ textDecoration: "none", fontSize: "16px", color: "#545D63", marginTop: "5px" }}>K37/A, Basement, Kailash Colony, New Delhi</p>
                    <p style={{ textDecoration: "none", fontSize: "16px", color: "#545D63", marginTop: "-10px" }}>hello@enbill.in</p>
                </div>
                <div className="col">
                    <p style={{ fontWeight: "600", fontSize: "16px" }}>Follow Us</p>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >

                        <div>
                            <a href="https://www.instagram.com/enbillbharat/" target="_blank" className="mt-4 btns">             <img style={{ padding: "5px" }} src={require("../Assets/Images/newinstalogo.png")} /></a>
                        </div>
                        <div>
                            <a href="https://www.facebook.com/EnBillBharat" target="_blank" className="mt-4 btns">   <img style={{ padding: "5px" }} src={require("../Assets/Images/newfacebooklogo.png")} /> </a>
                        </div>


                        <div>
                            <a href="https://twitter.com/Enbillbharat" target="_blank" className="mt-4 btns">  <img style={{ padding: "5px" }} src={require("../Assets/Images/newtwitterlogo.png")} /></a>
                        </div>
                        <div>
                            <a href="https://www.linkedin.com/company/enbill/mycompany/" target="_blank" className="mt-4 btns">  <img style={{ padding: "5px" }} src={require("../Assets/Images/newlinkdin.png")} /></a>
                        </div>




                    </div>



                </div>
                <div className="col d-none d-lg-block" >

                </div>
            </div>
            <div className="row" style={{ width: "100%", padding: "10px" }}>

                <div className="col-sm-6 col-md justify-content-lg-start justify-content-center" style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                    <div>
                        <p style={{ paddingTop: "20px", width: "100%", color: "#C3CCD3", font: "normal normal medium Raleway", fontSize: "12px" }}>© Copyright@EnBill-2023-All Rights Reserved.</p>
                    </div>

                </div>

                <div className="col-sm-6 col-md" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div>
                        <a href="/TermsOfUse" style={{ color: "#172026", marginRight: "20px" }}>Terms of Use</a>
                        <a href="/PrivacyPolicy" style={{ color: "#172026" }}>Privacy Policy</a>
                    </div>

                </div>
                <div className="col-sm-6 col-md justify-content-lg-end justify-content-center" style={{ display: "flex", alignItems: "center" }}>
                    <div>
                        <p style={{ paddingTop: "20px", margin: "0", width: "100%", font: "normal normal medium Raleway", fontSize: "10px" }}>CIN No.: 472900DL2021PTC384966</p>
                        <p style={{ paddingTop: "10px", margin: "0", width: "100%", font: "normal normal medium Raleway", fontSize: "10px" }}>GST No.: 09AAGCI2572N1Z2</p>
                    </div>

                </div>


            </div>
        </>
    )
}

export default EnbillUserPage

